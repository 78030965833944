export default (Vue) => {
  // if (this.installed) {
  //   return;
  // }

  if (!Vue.prototype.$auth) {
    Object.defineProperties(Vue.prototype, {
      $auth: {
        get() {
          const that = this;
          return (requiredPermissions) => {
            const [requiredPermission, requiredAction] = requiredPermissions.split('.');
            const { permissions } = that.$store.getters;
            return (
              permissions[requiredPermission]
                            && permissions[requiredPermission].includes(requiredAction)
            );
          };
        }
      }
    });
  }
};
