import axios from 'axios';
import notification from '@/components/Notification';

// #region config

const NAME = 'CIEAF_DEMO';

const KEY_TOKEN = `${NAME}_token`; // localstorage中存储的键名，该键值存储token信息
const KEY_REDIRECT = `${NAME}_tourl`; // localstorage中存储的键名，该键值存储SSO登录前最后的访问地址
const KEY_STATE = `${NAME}_state`; // localstorage中存储的STATE，该键值存储IAM登录前的state用于对比

const CLIENT_TYPE = 'pc'; // 客户端类型 pc / joychat
const REDIRECT_URL = `${process.env.VUE_APP_PUBLIC_PATH}`; // SSO登录成功跳转地址 / joychat验证失败跳转地址
const API_SSO_URL = '/oauth2/authorization/iam'; // 获取SSO登录跳转地址的api
const API_CREATE_TOKEN_BY_CODE = '/login/oauth2/code/iam'; // 初始化token的api。web携带code，app携带ticket
const API_REFRESH_TOKEN = '/sso/refresh'; // 刷新token的api，携带refreshToken

// 请求根路径
const BASE_URL = process.env.VUE_APP_PUBLIC_PATH + process.env.VUE_APP_API_BASE_URL.replace(/^\//, '');
const AXIOS_TIMEOUT = 60000; // 请求超时时长
const LOCK_TIMEOUT = 10000; // 锁定token超时时长

const KEY_SUB_APP = `${NAME}_sub_app`; // localstorage中存储的子应用名，切换子应用时Token自动过期
const ATT_SUB_APP = 'subapp'; // 请求json中，子应用的属性名

const ERR_HANDLER = (type, detail) => { // 提示错误
  switch (type) {
    case 'LOGIN_STATE_ERROR': {
      // alert('LOGIN STATE ERROR');
      notification.error({ message: 'LOGIN STATE ERROR', description: '' });
      break;
    } case 'RESPONSE_ERROR': {
      const { code, status, statusText } = detail;
      // alert(`RESPONSE ERROR: ${code} ${status} ${statusText}`);
      notification.error({ message: `HTTP[${status}]`, description: `${code} ${statusText}` });
      break;
    } case 'NET_ERROR': {
      const { code } = detail;
      // alert(`NET ERROR: ${code}`);
      notification.error({ message: `${code}`, description: '' });
      break;
    } case 'SERVE_ERROR': {
      const { message, code } = detail;
      // alert(`${message} ${code}`);
      notification.error({ message: `Error[${code}]`, description: `${message}` });
      break;
    } default: {
      notification.error({ message: `Unkonw Error[${type}]`, description: `${detail}` });
      break;
    }
  }
};

// #endregion

// #region myAxios

const myAxios = axios.create({ baseURL: BASE_URL, timeout: AXIOS_TIMEOUT });
const myAxios1 = axios.create({ baseURL: '/hrdworkbench/api/v1', timeout: AXIOS_TIMEOUT });

/* 请求拦截 */
myAxios1.interceptors.request.use((config) => config, (err) => Promise.reject(err));

/* 响应拦截 */
myAxios1.interceptors.response.use((response) => response, (err) => Promise.reject(err));

// #endregion

// #region utils

/** 等待几毫秒 */
function waitMs(ms) {
  return new Promise((next) => setTimeout(next, ms));
}

/** 操作localStorage.KEY_TOKEN val:undefined-取值 json-赋值 null-删除 */
function storage(val) {
  if (val === undefined) {
    const v = storage.get(KEY_TOKEN);
    return v ? JSON.parse(v) : null;
  }
  if (val === null) {
    storage.remove(KEY_TOKEN);
  } else {
    storage.set(KEY_TOKEN, JSON.stringify(val));
  }
}
const store = window.localStorage;
storage.get = (k) => store.getItem(k);
storage.set = (k, v) => store.setItem(k, v);
storage.remove = (k) => store.removeItem(k);

// #endregion

// #region subApp

/** 更新子应用，更新后当前token会过期，下次使用时会刷新 */
export async function updateSubApp(value) {
  let curToken = storage();
  if (curToken) {
    while (curToken.lock < Date.now()) {
      await waitMs(500);
      curToken = storage();
    }
    curToken.expire = 0;
    storage(curToken);
  }

  storage.remove(KEY_SUB_APP);
  if (value) {
    storage.set(KEY_SUB_APP, value);
  }
}

/** 获取当前子应用 */
export function getSubApp() {
  return storage.get(KEY_SUB_APP);
}
// #endregion

// #region tooken

/** 获取accessToken */
export async function getAccessToken() {
  let token1;
  do {
    // localstorage中没有痕迹，说明没登录过
    // 抛出token错误，触发sso重新登录
    token1 = storage();
    if (!token1) {
      throw tokenError('token in storage is empty');
    }

    // 如果token被锁，等待
    if (token1.lock) {
      if (token1.lock < Date.now()) {
        // 锁的时间太长，有可能是别的tab页面加锁后被终止了
        // 抛出token错误，触发sso重新登录
        throw tokenError('token lock is timeout');
      }
      await waitMs(500);
    }
  } while (token1.lock);

  // 有效期内，返回accessToken
  const now = Date.now();
  const { access, expire, refresh } = token1;
  if (expire > now) { return access; }

  // 过期需要刷新，先锁token
  storage({ lock: now + LOCK_TIMEOUT });

  // 刷新token
  const token2 = await fetchToken(API_REFRESH_TOKEN, { refreshToken: refresh })
    .catch((err) => {
      responseError(err);
      // 当错误没有触发sso登录（非401）
      // 解锁，并还原之前的token，下把继续用
      if (!err.toSSO) { storage(token1); }
      return Promise.reject(err);
    });

  storage(token2);
  return token2.access;
}

/** 从API中获取token */
async function fetchToken(url, data) {
  const subApp = storage.get(KEY_SUB_APP);
  if (subApp) {
    // data[ATT_SUB_APP] = subApp;
    url += `?${ATT_SUB_APP}=${subApp}`;
  }

  const res = await myAxios1.post(url, data);
  const { accessToken, refreshToken, accessExpire } = res.data;
  return {
    access: String(accessToken),
    refresh: String(refreshToken),
    expire: Date.now() + Number(accessExpire) * 1000
  };
}

/** 生成token错误 */
function tokenError(message) {
  const err = Error(message);
  err.name = 'tokenError';
  return err;
}
// #endregion

// #region login

function GetRequest() {
  const url = location.href; //获取url中"?"符后的字串
  let theRequest = new Object();
  if (url.indexOf("?") != -1 || url.indexOf("#") != -1) {
     let str = url.split('/#/')[1];
     let strs = str.split("&");
     for(let i = 0; i < strs.length; i ++) {
        theRequest[strs[i].split("=")[0]]=unescape(strs[i].split("=")[1]);
     }
  }
  return theRequest;
}

/** 登录sso系统 */
async function ssoLogin(href) {
  // // 清空状态
  // storage(null);

  let params = GetRequest();
  if(params && params.access_token){
    const res = await myAxios1.post("/sso/login",{        
        accessToken: params.access_token,
        endpoint: "PC"
    });
    
    const {
      url, accessToken, refreshToken, accessExpire
    } = res.data.data;
    
    // 如果返回了token，直接存储使用
    if (accessToken) {
      const mockToken = {
        access: String(accessToken),
        refresh: String(refreshToken),
        expire: Date.now() + Number(accessExpire) * 1000
      };
      storage(mockToken);
      console.warn('[CIEAF] MOCK USER START');
      return mockToken.access;
    }
  
    // if (!confirm("redirect to IAM?")) return;
  
    // 存储当前页面地址，为了SSO登录成功后重定向
    storage.set(KEY_REDIRECT, href || location.href);
  
    // 记录state，为了返回时验证来源
    const params1 = new URLSearchParams(url);
    const state = params1.get('state');
    storage.set(KEY_STATE, state);
  }else{
    if(!storage()){
      const res = await myAxios1.get("/sso/getConfig")
      .catch((err) => {
        responseError(err);
        return Promise.reject(err);
      });
      const config = res.data;
      console.log(res)
      console.log(config)
      if(window.location.href.includes('/#/')){//记录当前页面路由，sso登录后跳转
        localStorage.setItem('hrd_url',window.location.href.split('/#/')[1])
      }
      // const config = await ssoGetConfig();
      // store.commit('sso/setSSOconfig',config);
      let url = `${config.base_url}oauth2${config.realm_path}/authorize?client_id=${config.client_id}&state=${config.state}&scope=${config.scope}&redirect_uri=${config.redirect_uri}&response_type=${config.response_type}&nonce=${config.nonce}`;
      
      console.log(url)
      window.location.href = url;
      return false
    };
      
  }

  // if (CLIENT_TYPE === 'joychat') {
  //   window.location.href = REDIRECT_URL;
  //   return;
  // }

  // // 向后端请求跳转地址
  // const redirectUrl = REDIRECT_URL.replace(/^\//, `${location.origin}/`);
  // const apiUrl = `${API_SSO_URL.replace('/hrd', '')}?redirectUrl=${encodeURIComponent(redirectUrl)}&state=`;
  // const res = await myAxios1.get(apiUrl)
  //   .catch((err) => {
  //     responseError(err);
  //     return Promise.reject(err);
  //   });
  // const {
  //   url, accessToken, refreshToken, accessExpire
  // } = res.data;

  // // 如果返回了token，直接存储使用
  // if (accessToken) {
  //   const mockToken = {
  //     access: String(accessToken),
  //     refresh: String(refreshToken),
  //     expire: Date.now() + Number(accessExpire) * 1000
  //   };
  //   storage(mockToken);
  //   console.warn('[CIEAF] MOCK USER START');
  //   return mockToken.access;
  // }

  // // if (!confirm("redirect to IAM?")) return;

  // // 存储当前页面地址，为了SSO登录成功后重定向
  // storage.set(KEY_REDIRECT, href || location.href);

  // // 记录state，为了返回时验证来源
  // const params = new URLSearchParams(url);
  // const state = params.get('state');
  // storage.set(KEY_STATE, state);

  // // 转到sso登录地址
  // window.location.href = url;
}

/** 登录WEB系统，获取token，返回转向地址 */
export async function webLogin(code, state, subApp) {
  // 判断state
  if (state === storage.get(KEY_STATE)) {
    await updateSubApp(subApp);

    // const redirectUrl = REDIRECT_URL.replace(/^\//, `${location.origin}/`);
    // const token = await fetchToken(API_CREATE_TOKEN_BY_CODE, { code, state, redirectUrl })
    //   .catch((err) => {
    //     responseError(err);
    //     return Promise.reject(err);
    //   });

    // storage(token);
    const url = storage.get(KEY_REDIRECT);
    storage.remove(KEY_REDIRECT);
    storage.remove(KEY_STATE);

    return { url };
  }
  ERR_HANDLER('LOGIN_STATE_ERROR');
  ssoLogin(storage.get(KEY_REDIRECT));
  throw Promise.reject('LOGIN_STATE_ERROR');
}

// #endregion

// #region API

let apiIndex = 0;
class Api {
  constructor(axiosOpts, apiOpts = {}) {
    this.axiosOpts = axiosOpts;
    this.apiOpts = apiOpts;
  }

  /**
     * 开始/结束请求时调用
     * 可重写该方法实现节流
     * @typedef { (run:boolean, runId:number)=>void } Handler
     * @type {Handler}
     */
  handler(run, runId) {
    // console.log(`api-${runId}:${run ? "run" : "end"}`);
  }

  /**
     * 获取响应对象 Response
     * token错误时会跳转到sso登录
     * @param { Handler } [handler]
     * @returns { Promise<Response> }
     */
  async getResponse(handler) {
    // 自定义节流、公共节流、Set快捷节流操作
    handler = handler || this.handler;
    if (handler instanceof Set) {
      const set = handler;
      handler = (state, id) => { set[state ? 'add' : 'delete'](id); };
    }

    // 开启节流
    const index = ++apiIndex;
    handler(true, index);

    // 获取accessToken
    let accessToken;
    if (!this.apiOpts.ignoreToken) {
      accessToken = await getAccessToken()
        .catch(async (err) => {
          handler(false, index);
          // 只有token错误触发sso登录
          if (err.name === 'tokenError') {
            const mockAccessToken = await ssoLogin();
            if (mockAccessToken) {
              return mockAccessToken;
            }
          }
          return Promise.reject(err);
        });
    }

    // 准备请求
    const axiosOpts = { ...this.axiosOpts };
    axiosOpts.headers = {
      'access-token': accessToken,
      'Content-Type': 'application/json',
      'Accept-Language': localStorage.getItem('platform-lang') || 'en-US',
      ...axiosOpts.headers
    };

    // 发起请求
    const core = this.apiOpts.core || myAxios1;
    const res = await core(axiosOpts)
      .catch((err) => {
        handler(false, index);
        return Promise.reject(err);
      });

    // 关闭节流 为了串联请求时动画更流畅，加一个延时
    setTimeout(() => { handler(false, index); }, 100);
    return res;
  }

  /**
     * 获取响应体，Response.body
     * 自动处理httpStatus不为2xx的错误
     * @param { Handler } [handler]
     * @returns { Promise<any> }
     */
  async getBody(handler) {
    const res = await this.getResponse(handler)
      .catch((err) => {
        responseError(err);
        return Promise.reject(err);
      });

    return res.data;
  }

  /**
     * 获取响应结果，Response.body.data
     * 自动处理httpStatus不为2xx的错误
     * 自动处理json.errcode不为0的错误
     * @param { Handler } [handler]
     * @returns { Promise<any> }
     */
  async getData(handler) {
    const body = await this.getBody(handler);
    const { code, data, message } = body;
    if (code !== 200) {
      // 在ERR_HANDLER里配置统一的错误处理请求
      const err = serveError(code, message);

      // 继续抛出错误，供调用方继续处理
      // 不想外部接收到错误，可以去掉这一行
      // 如果希望webpack不弹出异常捕获层，可以设置vue.config.js devServer.client.overlay:false
      return Promise.reject(err);
    }

    return data;
  }
}

export const api = {
  request: (axiosOpts, apiOpts) => new Api({ ...axiosOpts }, apiOpts),
  get: (url, axiosOpts, apiOpts) => new Api({ ...axiosOpts, url, method: 'get' }, apiOpts),
  delete: (url, axiosOpts, apiOpts) => new Api({ ...axiosOpts, url, method: 'delete' }, apiOpts),
  head: (url, axiosOpts, apiOpts) => new Api({ ...axiosOpts, url, method: 'head' }, apiOpts),
  options: (url, axiosOpts, apiOpts) => new Api({ ...axiosOpts, url, method: 'options' }, apiOpts),
  post: (url, data, axiosOpts, apiOpts) => new Api({
    ...axiosOpts, url, data, method: 'post'
  }, apiOpts),
  put: (url, data, axiosOpts, apiOpts) => new Api({
    ...axiosOpts, url, data, method: 'put'
  }, apiOpts),
  patch: (url, data, axiosOpts, apiOpts) => new Api({
    ...axiosOpts, url, data, method: 'patch'
  }, apiOpts),
  storage: (val) => storage(val)
};

// #endregion

// #region error

/** 处理响应错误 */
function responseError(err) {
  const {
    name, code, response, used
  } = err;

  // getResponseBody的响应错误可能来自fetchToken
  // 该错误已经在getAccessToken中处理过
  // 应避免重复处理
  if (used) return;
  err.used = true;

  if (name === 'AxiosError') {
    // 响应错误处理
    // axios的0.x和1.x的错误处理模式不一样。QTMD
    if (response && response.status) {
      const { status, statusText } = response;
      switch (status) {
        case 401:
          // 标记已经由ssoLogin处理后续内容，无需还原现场
          err.toSSO = true;
          // 清空状态
          storage(null);
          if(window.location.href.includes('/#/')){//记录当前页面路由，sso登录后跳转
              localStorage.setItem('hrd_url',window.location.href.split('/#/')[1])
          }
          ssoLogin();
          break;
        default:
          ERR_HANDLER('RESPONSE_ERROR', { code, status, statusText });
          break;
      }
    } else {
      // 网络连接错误
      ERR_HANDLER('NET_ERROR', { code });
    }
  }
}

/** 处理业务错误 */
function serveError(code, message) {
  ERR_HANDLER('SERVE_ERROR', { message, code });
  const err = Error(message);
  err.name = 'SERVE_ERROR';
  return err;
}

// #endregion
