<template>
    <div class="b-container">
        <div class="b-header">
            <a-breadcrumb separator="/">
                <a-breadcrumb-item :key="route.name" v-for="route in matches">
                    <router-link :to="route.path">
                        {{ $t(`menu.${route.name}`) }}
                    </router-link>
                </a-breadcrumb-item>
            </a-breadcrumb>
        </div>
        <div class="b-inner">
            <div v-if="$slots.search">
                <div class="b-search" ref="search">
                    <a-form-model class="search-panel">
                        <a-row type="flex" justify="start" class="search-title">
                            <img
                                class="search-icon"
                                src="../../assets/img/search-light.png"
                                alt=""
                            >
                            <span>
                        {{ $t('menu.searchTitle') }}
                    </span>
                        </a-row>
                        <slot name="search"></slot>
                        <slot name="search-more" v-if="open"></slot>
                        <a-row
                            :gutter="24"
                            type="flex"
                            align="bottom"
                            justify="end"
                            style="margin-top: 20px"
                        >
                            <a-col>
                                <a-button type="link" v-if="openBtn" @click="open = !open">
                                    {{open?'Hide':'Open'}}
                                    <a-icon
                                        type="down-custom"
                                        theme="filled"
                                        :class="['open-arrow',open?'hide-arrow':'']"
                                    ></a-icon>
                                </a-button>
                            </a-col>
                            <slot name="search-action"></slot>
                        </a-row>
                    </a-form-model>
                </div>
            </div>
            <div class="b-container-list">
                <div class="b-operation" v-if="$slots['table-title'] || $slots.operation">
                    <div class="b-table-title">
                        <div class="custom-title">
                            <img
                                class="result-list-icon"
                                src="../../assets/img/team-light.png"
                                alt=""
                            >
                            <div>  {{ $t('menu.menuTitle') }}</div>
                            <slot name="table-title"></slot>
                        </div>
                        <slot name="operation"></slot>
                    </div>
                </div>
                <slot></slot>
            </div>

        </div>
    </div>
</template>

<script>
export default {
  name: 'LayoutContainer',
  data() {
    return {
      openBtn: false,
      open: false
    };
  },
  computed: {
    matches() {
      return this.$route.matched
        .filter((item) => item.name)
        .map((item) => {
          const { name, path } = item;
          return { name, path: path || '/' };
        });
    }
  },
  mounted() {
    // console.log(this.$scopedSlots['search-more']);
    if (this.$scopedSlots['search-more']) {
      this.openBtn = true;
    }
  }
};
</script>
<style lang="less" scoped>
@import (reference) '~@/assets/styles/variables.less';

.b-container {
    height: 100%;
    overflow: hidden;
    background-color: transparent;

    .b-header {
        position: relative;
        z-index: 2;
        padding: 2px 17px 16px;

        &.b-header--shadow {
            box-shadow: @shadow-1;
        }
    }

    .b-inner {
        height: calc(100% - 53px);
        padding: 0px 17px 0px;
        overflow-y: overlay;
        &::-webkit-scrollbar {
            width: 16px;
            //height: 8px;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 12px;
            //-webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
            //background: rgba(0,0,0,0.2);
            border: 6px solid rgba(0, 0, 0, 0);
            box-shadow: 8px 0 0 #a5adb7 inset;
            &:hover {
                box-shadow: 8px 0 0 #8e9bac inset;
            }
        }
        &::-webkit-scrollbar-track {
            border-radius: 0;
            background: transparent;
        }
    }
    /deep/ .ant-pagination-options{
        .ant-select{
            min-width: 140px;
        }
    }
    .b-search {
        display: flex;
        align-items: flex-end;
        width: 100%;
        margin-bottom: 8px;

        /deep/ .ant-form {
            width: 100%;

            // .ant-form-item {
            //     display: flex;
            // }

            .ant-btn,
            .ant-form-item,
            .ant-form-model-item {
                margin-bottom: 8px;
            }

            .ant-select,
            .ant-input {
                min-width: 175px;
            }
        }

        /deep/ .ant-col .ant-form-item-label {
            text-align: left;
        }

        /deep/ .ant-col .ant-form-item-label,
        /deep/ .ant-form-item-control {
            line-height: 32px;
        }
    }

    .b-operation {
        display: flex;
        align-items: center;
        width: 100%;
        margin-bottom: 16px;

        .b-table-title {
            margin-right: auto;
            display: flex;
            width: 100%;
            justify-content: space-between;

        }
    }

    /deep/ .ant-breadcrumb .ant-breadcrumb-link {
        .router-link-active:not(.router-link-exact-active) {
            color: rgba(15, 48, 122, 0.6);

            &:hover {
                color: @primary-hover;
            }
        }

        .router-link-exact-active {
            color: #0F307A;
            font-weight: bold;
        }
    }
    /deep/ .ant-breadcrumb-separator{
        color: rgba(15, 48, 122, 0.6);
    }
    /deep/ .open-arrow{
        font-size: 12px;
        transition: all .3s;
    }
    /deep/ .hide-arrow{
        transform: rotate(180deg);
    }
}
</style>
