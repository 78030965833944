import Container from './components/Container';
import SideMenuLayout from './layouts/SideMenuLayout';
import TopSideMenuLayout from './layouts/TopSideMenuLayout';
import './index.less';

export const LAYOUT_TYPES = {
  TOP_SIDE: 'TOP_SIDE',
  SIDE_ONLY: 'SIDE_ONLY'
};

export default {
  name: 'b-layout',
  props: {
    logo: {
      type: File | String
    },
    type: {
      type: String,
      default: LAYOUT_TYPES.TOP_SIDE
    },
    title: {
      type: String
    },
    menus: {
      type: Array,
      default: []
    },
    i18nRender: {
      type: Function
    },
    activeMenus: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      openKey: '',
      selectedKey: ''
    };
  },
  components: {
    [SideMenuLayout.name]: SideMenuLayout,
    [TopSideMenuLayout.name]: TopSideMenuLayout
  },
  render(h) {
    const data = {
      ...Object.keys(this)
        .filter((key) => key.startsWith('$'))
        .reduce(
          (acc, key) => ({
            ...acc,
            [key.slice(1)]: this[key]
          }),
          {}
        )
    };
    let { name } = TopSideMenuLayout;
    if (this.type === LAYOUT_TYPES.SIDE_ONLY) {
      name = SideMenuLayout.name;
    }
    return h(name, {
      ...data,
      props: {
        ...data.props,
        logo: this.logo,
        menus: this.menus,
        title: this.title,
        i18nRender: this.i18nRender,
        activeMenus: this.activeMenus
      }
    });
  }
};

export { TopSideMenuLayout, SideMenuLayout, Container };
