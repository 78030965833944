/*
 * @Date: 2020-12-21 09:42:40
 * @Description: main
 */
import 'core-js/stable';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'regenerator-runtime/runtime';
import Vue from 'vue';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// import { Cascader } from 'element-ui';
// import elCascaderMulti from 'el-cascader-multi';
import {
  Spin, Steps, Upload, Icon
} from '@babylon/design';
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  Pagination, Cascader
} from 'ant-design-vue';
import doitUIWeb from 'doit-ui-web';
import App from '@/App.vue';
import store from '@/store';
import router from '@/router';
import Loading from '@/assets/img/loading.svg';
import i18nMixin from '@/mixins/i18n.mixin';
import './utils/importComponents';
import 'doit-ui-web/lib/doituiweb.css';
// import { Installer } from '@/utils/request';
import PermissionHelper from '@/directives/permission';
import locales, { defaultLang } from '@/locales';
import { webLogin } from '@/utils/request/tokenApi';

import '@/utils/filters';
import '@/directives/auth';
// styles globally
// mock
// WARNING: `mockjs` NOT SUPPORT `IE` PLEASE DO NOT USE IN `production` ENV.
// import './mock';

function runVueApp() {
  Spin.setDefaultIndicator({
    indicator: (h) => h('img', { attrs: { src: Loading, alt: 'loading' } })
  });

  Vue.config.productionTip = false;

  // Vue.use(Installer);
  Vue.use(PermissionHelper);
  Vue.use(Steps);
  Vue.use(doitUIWeb);
  Vue.use(Upload);
  Vue.use(Icon);
  // mixins
  Vue.mixin(i18nMixin);
  Vue.use(Pagination);
  Vue.use(Cascader);
  Vue.use(ElementUI);
  // Vue.use(elCascaderMulti);              `
  store.dispatch('setLang', defaultLang, { root: true });

  new Vue({
    router,
    store,
    i18n: locales,
    render: (h) => h(App)
  }).$mount('#app');
}

(() => {
  const params = new URLSearchParams(location.search);
  const code = params.get('code');
  const state = params.get('state');
  if (code && state) {
    webLogin(code, state).then((r) => { location.href = r.url; });
  } else {
    runVueApp();
  }
})();
