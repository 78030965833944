/*
 * @Date: 2021-02-02 17:38:03
 * @Description: Menu
 */
import { Menu } from '@babylon/design';

const renderIcon = (h, { icon = '' }) => {
  if (icon && typeof icon === 'object') {
    const isBIcon = icon.theme === 'custom';
    if (isBIcon) {
      return h('b-icon', {
        props: { name: icon.type, width: 16, height: 16 },
        class: 'anticon anticon-menu'
      });
    }
    return h('a-icon', { props: { type: icon.type, theme: icon.theme } });
  } if (icon && icon.includes('svg')) { // 使用svg图片时
    return h('img', {
      style: { marginBottom: '3px', marginLeft: '-4px' },
      attrs: { src: icon }
    });
  }
  return '';
};

const renderMenuItem = (h, item, i18n) => {
  if (item.hidden) {
    return null;
  }

  const locale = `menu.${item.name}`;
  const icon = renderIcon(h, { icon: item.meta.icon });
  const text = h(
    'span',
    {
      attrs: {
        style: ''
      }
    },
    i18n(locale)
  );
  return h('a-menu-item', { key: item.path }, [icon, text]);
};

const renderSubmenu = (h, item, i18n) => {
  const icon = renderIcon(h, { icon: item.meta.icon });
  const children = item.children || [];
  const slotTitle = h('h4', { slot: 'title' }, [icon, h('span', i18n(`menu.${item.name}`))]);
  return h(
    'a-sub-menu',
    {
      key: item.path
    },
    // eslint-disable-next-line no-use-before-define
    [slotTitle, ...children.map((child) => renderMenu(h, child, i18n))]
  );
};

const renderMenu = (h, item, i18n) => {
  if (item.children && item.children.length > 0) {
    return renderSubmenu(h, item, i18n);
  }
  return renderMenuItem(h, item, i18n);
};

export default {
  name: 'menus',
  props: {
    menus: {
      type: Array,
      default: () => []
    }
  },
  inject: ['i18nRender'],
  mixins: [Menu],
  render(h) {
    this.$slots.default = this.menus
      .map((item) => renderMenu(h, item, this.i18nRender))
      .filter(Boolean);
    return Menu.render.call(this, h);
  }
};
