import Vue from 'vue';
import '@babylon/design/dist/light.min.css';
import { Container } from '@/layout';

import ActionButton from '@/components/ActionButton/index';
import BAutoComplete from '@/components/AutoComplete/index.vue';
import Dictionary from '@/components/Dictionary/index.vue';
// eslint-disable-next-line import/namespace,import/default
import BTable from '@/components/Table/index';
import {
  Dropdown,
  Badge,
  Icon,
  Empty,
  Button,
  Menu,
  Breadcrumb,
  ConfigProvider,
  Divider,
  Avatar,
  Drawer,
  Spin,
  FormModel,
  Table,
  Row,
  Col,
  Tooltip,
  Popconfirm,
  Select,
  Input,
  Tabs,
  Tag,
  Form,
  DatePicker,
  Modal,
  Descriptions,
  AutoComplete,
  Checkbox,
  Switch,
  Radio,
  message,
  InputNumber,
  Result,
  Tree,
  TreeSelect
} from '@babylon/design';

// Custom components
Vue.component('b-action-btn', ActionButton);
Vue.component('b-container', Container);
Vue.component('b-auto-complete', BAutoComplete);
Vue.component('bTable', BTable);
Vue.component('b-dict', Dictionary);

// Ant Design Vue Components
Vue.prototype.$message = message;

Vue.use(Button)
  .use(Icon)
  .use(Row)
  .use(Col)
  .use(Breadcrumb)
  .use(Dropdown)
  .use(Menu)
  .use(AutoComplete)
  .use(Checkbox)
  .use(DatePicker)
  .use(Form)
  .use(FormModel)
  .use(Input)
  .use(InputNumber)
  .use(Radio)
  .use(Select)
  .use(Switch)
  .use(Avatar)
  .use(Badge)
  .use(Descriptions)
  .use(Empty)
  .use(Table)
  .use(Tabs)
  .use(Tag)
  .use(Tooltip)
  .use(Drawer)
  .use(Modal)
  .use(Popconfirm)
  .use(Spin)
  .use(ConfigProvider)
  .use(Result)
  .use(Tree)
  .use(TreeSelect)
  .use(Divider);
