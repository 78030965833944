import Menus from '../components/Menu';

export default {
  data() {
    return {
      openKeys: [],
      collapsed: false,
      selectedKeys: []
    };
  },
  props: {
    menus: {
      type: Array,
      default: []
    },
    logo: {
      // eslint-disable-next-line no-bitwise
      type: File | String
    },
    title: {
      type: String,
      require: true
    },
    i18nRender: {
      type: Function,
      default: (val) => val
    },
    activeMenus: {
      type: Array,
      default: []
    },
    //! events
    onMenu: {
      type: Function
    }
  },
  watch: {
    activeMenus(val) {
      this.init(val);
    }
  },
  provide() {
    return {
      i18nRender: this.i18nRender
    };
  },
  methods: {
    init(val) {
      this.openKeys = val.slice(0, val.length - 1);
      this.selectedKeys = val;
    },
    resize() {
      const innerWidth = 1000;
      if (window.innerWidth <= innerWidth) {
        this.collapsed = true;
      }
    },
    toggle() {
      this.collapsed = !this.collapsed;
      if (this.collapsed) {
        this.openKeys = [];
      } else {
        this.openKeys = [].concat(this.selectedKeys);
      }
    },
    click4menu(menu) {
      if (menu.key === this.$route.path) {
        return;
      }
      if (typeof this.onMenu === 'function') {
        this.onMenu(menu);
      } else {
        this.$router.push(menu.key);
      }
    }
  },
  created() {
    this.init(this.activeMenus);
    window.addEventListener('resize', this.resize.bind(this));
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resize.bind(this));
  },
  components: {
    menus: Menus
  }
};
