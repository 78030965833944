/*
 * @Date: 2021-01-28 12:31:41
 * @Description:  api/meta
 */
import request from '@/utils/request/index';

export const getDictionaryByKey = (code) => request({
  url: `/dicts/${code}/items`,
  method: 'GET'
});
