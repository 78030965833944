export default {
  namespaced: true,
  state: {
    progressList: []
  },
  mutations: {
    setProgress(state, progressObj) {
      // 修改进度列表
      const existedObj = state.progressList.find((item) => item.id === progressObj.id);
      if (existedObj) {
        // 前面说的path时间戳是唯一存在的，所以如果在进度列表中找到当前的进度对象
        // state.progressList.find((item)
        // => item.id == progressObj.id).progress = progressObj.progress; // 改变当前进度对象的progress
        if (progressObj.progress) {
          existedObj.progress = progressObj.progress;
        }
        if (progressObj.total) {
          existedObj.total = progressObj.total;
        }
        if (progressObj.time) {
          existedObj.time = progressObj.time;
        }
      } else {
        state.progressList.push(progressObj); // 当前进度列表为空，没有下载任务，直接将该进度对象添加到进度数组内
      }
    },
    delProgress(state, props) {
      // 删除进度列表中的进度对象
      if (props) {
        state.progressList.splice(
          state.progressList.findIndex((item) => item.id === props),
          1
        );
      } else {
        state.progressList = [];
      }
    }
  }
};
