<template>
    <a-config-provider :locale="locale">
        <div id="app">
            <a-spin :spinning="this.$store.state.loadding">
                <router-view />
            </a-spin>
        </div>
    </a-config-provider>
</template>

<script>
import i18n from '@/locales';
import { setDocumentTitle } from '@/utils/helpers/dom';

const { VUE_APP_TITLE } = process.env;

export default {
  data() {
    return {};
  },
  computed: {
    locale() {
      // 只是为了切换语言时，更新标题
      const { name } = this.$route;
      if (name) {
        setDocumentTitle(i18n.t(`menu.${name}`), VUE_APP_TITLE);
      }
      return this.$i18n.getLocaleMessage(this.$store.getters.lang).ant;
    }
  }
};
</script>
<style lang="less">
@import (reference) '~@/assets/styles/variables.less';
.btn__edit,
.btn__delete {
    margin: 0 16px 0 -4px !important;
    padding: 4px;
}

.btn__edit {
    color: @primary !important;

    &:hover {
        color: @primary-hover !important;
        background-color: #fff;
    }
}
.btn__delete {
    color: @danger !important;

    &:hover {
        color: @danger-hover !important;
        background-color: #fff;
    }
}
.ant-row-flex-end{
    margin-top: 0 !important;
}
</style>
