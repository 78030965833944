const byteFormat = (bytes) => {
  if (bytes === 0) {
    return '0B';
  }
  const k = 1000; // or 1024
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  const three = 3;
  return (bytes / k ** i).toPrecision(three) + sizes[i];
};

/**
 * 触发 window.resize
 */
const triggerWindowResizeEvent = () => {
  const event = document.createEvent('HTMLEvents');
  event.initEvent('resize', true, true);
  event.eventType = 'message';
  window.dispatchEvent(event);
};

const handleScrollHeader = (callback) => {
  let timer = 0;

  let beforeScrollTop = window.pageYOffset;
  // eslint-disable-next-line no-param-reassign
  callback = callback
        || null;
  window.addEventListener(
    'scroll',
    () => {
      const timeout = 50;
      clearTimeout(timer);
      // eslint-disable-next-line consistent-return
      timer = setTimeout(() => {
        let direction = 'up';
        const afterScrollTop = window.pageYOffset;
        const delta = afterScrollTop - beforeScrollTop;
        if (delta === 0) {
          return false;
        }
        if (delta > 0) {
          direction = 'down';
        }
        callback(direction);
        beforeScrollTop = afterScrollTop;
      }, timeout);
    },
    false
  );
};

const isIE = () => {
  const bw = window.navigator.userAgent;
  const compare = (s) => bw.indexOf(s) >= 0;
  const ie11 = (() => 'ActiveXObject' in window)();
  return compare('MSIE') || ie11;
};

/**
 * Remove loading animate
 * @param id parent element id or class
 * @param timeout
 */
const timeoutNumber = 1500;
const removeLoadingAnimate = (id = '', timeout = timeoutNumber) => {
  if (id === '') {
    return;
  }
  setTimeout(() => {
    document.body.removeChild(document.getElementById(id));
  }, timeout);
};

const getPrimaryType = (params) => {
  const eight = 8;
  // Object.prototype.toString.call(params).slice(1 + 1 + 1 + 1 + 1 + 1 + 1 + 1, -1);
  return Object.prototype.toString.call(params).slice(eight, -1);
};

const requireAll = (context) => context.keys().map(context);

const transfer2ids = (array) => {
  if (getPrimaryType(array) !== 'Array') {
    return array;
  }

  return array.reduce(
    (acc, item) => ({
      byId: {
        ...acc.byId,
        [item.id]: item
      },
      allIds: [...new Set([...acc.allIds, item.id])]
    }),
    { byId: {}, allIds: [] }
  );
};
export {
  byteFormat,
  triggerWindowResizeEvent,
  handleScrollHeader,
  isIE,
  removeLoadingAnimate,
  getPrimaryType,
  requireAll,
  transfer2ids
};
