/* eslint-disable camelcase */
/*
 * @Date: 2020-12-21 09:42:40
 * @Description: locales
 */

import Vue from 'vue';
import moment from 'moment';
import VueI18n from 'vue-i18n';
// import momentEU from 'moment/locale/eu';
import CN_antd from '@babylon/design/lib/locale-provider/zh_CN';
import EN_antd from '@babylon/design/lib/locale-provider/en_US';
// import momentCN from 'moment/locale/zh-cn';

import enUS from '@/locales/lang/en-US';
import zhCN from '@/locales/lang/zh-CN';

// default lang
const storage = window.localStorage;

Vue.use(VueI18n);

const locales = {
  'en-US': {
    ...enUS,
    ant: EN_antd,
    moment: {
      name: 'eu'
      // locale: momentEU
    }
  },
  'zh-CN': {
    ...zhCN,
    ant: CN_antd,
    moment: {
      name: 'zh-cn'
      // locale: momentCN
    }
  }
};

export const defaultLang = storage.getItem('platform-lang') || 'en-US';

const i18n = new VueI18n({
  locale: defaultLang,
  messages: locales,
  fallbackLocale: defaultLang,
  silentTranslationWarn: true
});

const setI18nLanguage = (lang) => {
  i18n.locale = lang;
  const locale = locales[lang];
  moment.updateLocale(locale.moment.name, locale.moment.locale);
  storage.setItem('platform-lang', lang);
  document.querySelector('html').setAttribute('lang', lang);
  return lang;
};

export default i18n;
export {
  setI18nLanguage
};
