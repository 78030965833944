<template>
    <b-container class="initiationRecord">
        <template v-slot:search>
            <a-row :gutter="24" type="flex" align="bottom">
                <a-col :span="4">
                    <a-form-model-item label="Applicant">
                        <a-input v-model="queryParam.applicant" placeholder="Please Input" :maxLength="100"/>
                    </a-form-model-item>
                </a-col>
                <a-col :span="4">
                    <a-form-model-item label="Question 1">
                        <a-select v-model="queryParam.q1" placeholder="Please Select" allow-clear>
                            <a-select-option value="yes 是">是</a-select-option>
                            <a-select-option value="no 否">否</a-select-option>
                        </a-select>
                    </a-form-model-item>
                </a-col>
                <a-col :span="4">
                    <a-form-model-item label="Question 2">
                        <a-select v-model="queryParam.q2" placeholder="Please Select" allow-clear>
                            <a-select-option value="yes 是">是</a-select-option>
                            <a-select-option value="no 否">否</a-select-option>
                        </a-select>
                    </a-form-model-item>
                </a-col>
                <a-col :span="4">
                    <a-form-model-item label="Question 3">
                        <a-select v-model="queryParam.q3" placeholder="Please Select" allow-clear>
                            <a-select-option value="yes 是">是</a-select-option>
                            <a-select-option value="no 否">否</a-select-option>
                        </a-select>
                    </a-form-model-item>
                </a-col>
                <a-col :span="4">
                    <a-form-model-item label="Question 4">
                        <a-select v-model="queryParam.q4" placeholder="Please Select" allow-clear>
                            <a-select-option value="yes 是">是</a-select-option>
                            <a-select-option value="no 否">否</a-select-option>
                        </a-select>
                    </a-form-model-item>
                </a-col>
                <a-col :span="4">
                    <a-form-model-item label="Question 5">
                        <a-select v-model="queryParam.q5" placeholder="Please Select" allow-clear>
                            <a-select-option value="yes 是">是</a-select-option>
                            <a-select-option value="no 否">否</a-select-option>
                        </a-select>
                    </a-form-model-item>
                </a-col>
                <a-col :span="4">
                    <a-form-model-item label="Question 6">
                        <a-select v-model="queryParam.q6" placeholder="Please Select" allow-clear>
                            <a-select-option value="yes 是">是</a-select-option>
                            <a-select-option value="no 否">否</a-select-option>
                        </a-select>
                    </a-form-model-item>
                </a-col>
                <a-col :span="4">
                    <a-form-model-item label="Question 7">
                        <a-select v-model="queryParam.q7" placeholder="Please Select" allow-clear>
                            <a-select-option value="0">是</a-select-option>
                            <a-select-option value="1">否</a-select-option>
                        </a-select>
                    </a-form-model-item>
                </a-col>
                <a-col :span="4" style="text-align: right;margin-top: 15px;">
                    <a-button type="primary" @click="refreshTable()">
                        <a-icon type="search-custom" theme="filled"></a-icon>
                        {{ $t('label.search') }}
                    </a-button>
                    <a-button @click="handleExport()"  style="margin-left: 8px">
                        <a-icon type="reset-custom" theme="filled"></a-icon>
                        Export
                    </a-button>
                </a-col>
            </a-row>
        </template>
        <template v-slot:table-title>
            <div>
                {{ $t('menu.menuTitle.initiation') }}
            </div>
        </template>
        <a-table
            style="height: calc(100%)"
            ref="table"
            row-key="formId"
            :loading="loading"
            :pagination="pagination"
            :columns="columns"
            :data-source="dataList"
            :scroll="{ x: 9130}"
            @change="fetchingInitiation"
        >
            <template slot="lmApprovalTime" slot-scope="text, record">
                <span v-if="text">{{text[0]}}-{{text[1]}}-{{text[2]}}&ensp;{{text[3]}}:{{text[4]}}:{{text[5]}}</span>
            </template>
            <template slot="hrbpApprovalTime" slot-scope="text, record">
                <span v-if="text">{{text[0]}}-{{text[1]}}-{{text[2]}}&ensp;{{text[3]}}:{{text[4]}}:{{text[5]}}</span>
            </template>
            <template slot="bizsmApprovalTime" slot-scope="text, record">
                <span v-if="text">{{text[0]}}-{{text[1]}}-{{text[2]}}&ensp;{{text[3]}}:{{text[4]}}:{{text[5]}}</span>
            </template>
            <template slot="hrsmApprovalTime" slot-scope="text, record">
                <span v-if="text">{{text[0]}}-{{text[1]}}-{{text[2]}}&ensp;{{text[3]}}:{{text[4]}}:{{text[5]}}</span>
            </template>
            <template slot="bizheadApprovalTime" slot-scope="text, record">
                <span v-if="text">{{text[0]}}-{{text[1]}}-{{text[2]}}&ensp;{{text[3]}}:{{text[4]}}:{{text[5]}}</span>
            </template>
            <template slot="hrmheadApprovalTime" slot-scope="text, record">
                <span v-if="text">{{text[0]}}-{{text[1]}}-{{text[2]}}&ensp;{{text[3]}}:{{text[4]}}:{{text[5]}}</span>
            </template>
            <template slot="bizsvpApprovalTime" slot-scope="text, record">
                <span v-if="text">{{text[0]}}-{{text[1]}}-{{text[2]}}&ensp;{{text[3]}}:{{text[4]}}:{{text[5]}}</span>
            </template>
            <template slot="hrmsvpApprovalTime" slot-scope="text, record">
                <span v-if="text">{{text[0]}}-{{text[1]}}-{{text[2]}}&ensp;{{text[3]}}:{{text[4]}}:{{text[5]}}</span>
            </template>
            <template slot="bizvpApprovalTime" slot-scope="text, record">
                <span v-if="text">{{text[0]}}-{{text[1]}}-{{text[2]}}&ensp;{{text[3]}}:{{text[4]}}:{{text[5]}}</span>
            </template>
        </a-table>

    </b-container>
</template>

<script>
import { getCoiReportList, getCoiReportListDownload } from '@/api/system/reporting';
import { download } from '@/utils/request';

export default {
  data() {
    return {
      // 查询参数
      queryParam: {
        q1: undefined,
        q2: undefined,
        q3: undefined,
        q4: undefined,
        q5: undefined,
        q6: undefined,
        q7: undefined,
        applicant: null
      },
      date: undefined,
      dataList: [],
      loading: false,
      pagination: {
        total: 1,
        current: 1,
        pageSize: 10,
        showSizeChanger: true, // 是否可以改变 pageSize
        pageSizeOptions: ['10', '20', '50', '100'], // 指定每页可以显示多少条
        showTotal: (total) => ` ${total} records`,
        showQuickJumper: true, // 是否可以快速跳转至某页,
        hideOnSinglePage: false,
        showLessItems: true
      },
      columns: [
        {
          title: 'Form ID',
          dataIndex: 'formId',
          width: 200
        },
        {
          title: 'EE Code',
          dataIndex: 'uid',
          width: 200

        },
        {
          title: 'EE Name',
          dataIndex: 'employeeName',
          width: 200
        },
        {
          title: 'BP',
          dataIndex: 'bp',
          width: 200
        },
        {
          title: 'Department',
          dataIndex: 'departmentName',
          width: 500
        },
        {
          title: 'Q1',
          dataIndex: 'q1',
          width: 100

        },
        {
          title: 'Q2',
          dataIndex: 'q2',
          width: 100

        },
        {
          title: 'Q3',
          dataIndex: 'q3',
          width: 100

        },
        {
          title: 'Q4',
          dataIndex: 'q4',
          width: 100

        },
        {
          title: 'Q5',
          dataIndex: 'q5',
          width: 100

        },
        {
          title: 'Q6',
          dataIndex: 'q6',
          width: 100
        },
        {
          title: 'Q7',
          dataIndex: 'q7',
          width: 100
        },
        {
          title: 'Background & Justification for Decision',
          dataIndex: 'background',
          width: 300
        },
        {
          title: 'Decision A. Evaluation Result',
          dataIndex: 'decisionA',
          width: 300
        },
        {
          title: 'Decision B. Follow-up Action',
          dataIndex: 'decisionB',
          width: 200
        },
        {
          title: 'Joint Evaluators',
          width: 201,
          dataIndex: 'jointEvaluators'
        },
        {
          title: 'Line Manager',
          width: 201,
          dataIndex: 'lm'
        },
        {
          title: 'Approval Status1',
          width: 201,
          dataIndex: 'lmApprovalStatus'
        },
        {
          title: 'Approval Time1',
          width: 201,
          dataIndex: 'lmApprovalTime',
          scopedSlots: { title: 'title.lmApprovalTime', customRender: 'lmApprovalTime' }
        },
        {
          title: 'HR BP',
          width: 301,
          dataIndex: 'hrbp'
        },
        {
          title: 'Approval Status2',
          width: 201,
          dataIndex: 'hrbpApprovalStatus'
        },
        {
          title: 'Approval Time2',
          width: 201,
          dataIndex: 'hrbpApprovalTime',
          scopedSlots: { title: 'title.hrbpApprovalTime', customRender: 'hrbpApprovalTime' }
        },
        {
          title: 'Biz. SM',
          width: 301,
          dataIndex: 'bizsm'
        },
        {
          title: 'Approval Status3',
          width: 201,
          dataIndex: 'bizsmApprovalStatus'
        },
        {
          title: 'Approval Time3',
          width: 201,
          dataIndex: 'bizsmApprovalTime',
          scopedSlots: { title: 'title.bizsmApprovalTime', customRender: 'bizsmApprovalTime' }
        },
        {
          title: 'HRM SM',
          width: 301,
          dataIndex: 'hrmsm'
        },
        {
          title: 'Approval Status4',
          width: 201,
          dataIndex: 'hrsmApprovalStatus'
        },
        {
          title: 'Approval Time4',
          width: 201,
          dataIndex: 'hrsmApprovalTime',
          scopedSlots: { title: 'title.hrsmApprovalTime', customRender: 'hrsmApprovalTime' }
        },
        {
          title: 'Biz. Head',
          width: 301,
          dataIndex: 'bizhead'
        },
        {
          title: 'Approval Status5',
          width: 201,
          dataIndex: 'bizheadApprovalStatus'
        },
        {
          title: 'Approval Time5',
          width: 201,
          dataIndex: 'bizheadApprovalTime',
          scopedSlots: { title: 'title.bizheadApprovalTime', customRender: 'bizheadApprovalTime' }
        },
        {
          title: 'HRM Head',
          width: 301,
          dataIndex: 'hrmhead'
        },
        {
          title: 'Approval Status6',
          width: 201,
          dataIndex: 'hrmheadApprovalStatus'
        },
        {
          title: 'Approval Time6',
          width: 201,
          dataIndex: 'hrmheadApprovalTime',
          scopedSlots: { title: 'title.hrmheadApprovalTime', customRender: 'hrmheadApprovalTime' }
        },
        {
          title: 'Biz. VP',
          width: 201,
          dataIndex: 'bizvp'
        },
        {
          title: 'Approval Status7',
          width: 201,
          dataIndex: 'bizvpApprvoalStatus'
        },
        {
          title: 'Approval Time7',
          width: 201,
          dataIndex: 'bizvpApprovalTime',
          scopedSlots: { title: 'title.bizvpApprovalTime', customRender: 'bizvpApprovalTime' }
        },
        {
          title: 'Biz. SVP',
          width: 301,
          dataIndex: 'bizsvp'
        },
        {
          title: 'Approval Status8',
          width: 201,
          dataIndex: 'bizsvpApprvoalStatus'
        },
        {
          title: 'Approval Time8',
          width: 201,
          dataIndex: 'bizsvpApprovalTime',
          scopedSlots: { title: 'title.bizsvpApprovalTime', customRender: 'bizsvpApprovalTime' }
        },
        {
          title: 'HRM SVP',
          dataIndex: 'hrmsvp',
          width: 301
        },
        {
          title: 'Approval Status9',
          dataIndex: 'hrmsvpApprovalStatus',
          width: 201
        },
        {
          title: 'Approval Time9',
          dataIndex: 'hrmsvpApprovalTime',
          width: 201,
          scopedSlots: { title: 'title.hrmsvpApprovalTime', customRender: 'hrmsvpApprovalTime' }
        }
      ],
      headers: {
        authorization: 'authorization-text'
      }
    };
  },
  created() {
    this.fetchingInitiation(this.pagination);
  },
  activated() {
    this.fetchingInitiation(this.pagination);
  },
  methods: {
    // eslint-disable-next-line consistent-return
    fetchingInitiation(pagination) {
      if (this.loading) {
        return false;
      }
      this.loading = true;
      getCoiReportList({
        pageNo: pagination.current,
        pageSize: pagination.pageSize,
        ...this.queryParam
      }).then((result) => {
        this.dataList = result.data;
        this.pagination.total = ~~result.total;
        this.pagination.current = ~~result.pageNo;
        this.pagination.pageSize = ~~result.pageSize;
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      });
    },
    refreshTable() {
      console.log(typeof (this.queryParam.type));
      this.fetchingInitiation({ current: 1, pageSize: this.pagination.pageSize });
    },
    resetSearch() {
      this.date = undefined;
      this.queryParam = {
        q1: undefined,
        q2: undefined,
        q3: undefined,
        q4: undefined,
        q5: undefined,
        q6: undefined,
        q7: undefined,
        applicant: null
      };
    },
    // 导出
    async handleExport() {
      this.$store.state.loadding = true;
      await download('/coiApp/report/export', this.queryParam, 'report.xlsx');
      this.$store.state.loadding = false;
    }
  },
  filters: {}
};
</script>
<style lang="less">
.initiationRecord {
    .custom-title {
        :nth-child(2) {
            display: none
        }
    }
}
</style>
<style scoped lang="less">
///deep/ .ant-calendar-picker-input.ant-input {
//    color: unset;
//}
//
///deep/ .ant-select-selection-selected-value {
//    color: unset;
//}
/deep/ .b-header{
    display: none;
}
/deep/ .search-panel{
    margin-top: 20px;
}
///deep/ .pro-layout-body{
//   height: 100%;
//}
/deep/ .b-inner{
    height: calc(100vh - 53px) !important;
}
</style>
