/* eslint-disable no-unused-vars */
import Vue from 'vue';
import Vuex from 'vuex';

import loading from '@/store/plugins/loading';
import { setI18nLanguage } from '@/locales';

// modules
import menu from '@/store/modules/menu';
import user from '@/store/modules/user';
import notification from '@/store/modules/notification';
import common from '@/store/modules/common';
import progress from '@/store/modules/progress';

Vue.use(Vuex);

const store = new Vuex.Store({
  state() {
    return {
      lang: 'en-US',
      loadding: false
    };
  },
  getters: {
    lang(state) {
      return state.lang;
    },
    isLoading(state) {
      return state.loadding;
    }
  },
  strict: !!process.env.VUE_APP_IS_PROD,
  actions: {
    async setLang({ commit }, lang) {
      commit('setLang', lang);
      await setI18nLanguage(lang);
    },
    async setLoadding({ commit }, load) {
      commit('showLoadding', load);
    }
  },
  mutations: {
    setLang(state, lang) {
      state.lang = lang;
    },
    showLoadding(state, load) {
      state.loadding = load;
    }
  },
  modules: {
    menu,
    user,
    notification,
    common,
    progress
  },
  plugins: [loading]
});

export default store;
