import staticRouterComponents from './static-router-components';

export { staticRouterComponents };

const resolveComponent = (component) => {
  if (typeof component === 'function') {
    return component;
  }
  //! If async-router generator has error with component string path, please check this.
  // console.log(component, name, !!staticRouterComponents[component]);
  if (staticRouterComponents[component]) {
    return staticRouterComponents[component];
  }
  return () => import(`@/views/${component}`);
};

const resolveRouterItem = (router) => {
  const meta = { ...(router.meta || {}) };
  if (Object.prototype.hasOwnProperty.call(router, 'permissions')) {
    meta.permissions = [...router.permissions];
  }
  const componentList = () => {
    if (router.component) {
      return { component: resolveComponent(router.component) };
    }
    return {};
  };
  return {
    ...router,
    meta: { ...meta },
    ...componentList()
  };
};

const accessable = (router, permissions) => {
  if (router.permissions && router.permissions.length > 0) {
    const arr = router.permissions.map((item) => item.split('.'));
    // eslint-disable-next-line no-restricted-syntax
    for (const i of arr) {
      if (permissions.hasOwnProperty.call(permissions, i[0])
          && permissions[i[0]].indexOf(i[1]) !== -1) {
        return true;
      }
    }
    return false;
  }
  return true;
};

const resolveRoutes = (routers, permissions) => routers
  .map((router) => {
    if (!accessable(router, permissions)) {
      return null;
    }
    const item = resolveRouterItem(router);
    const childrenList = () => {
      if (item.children && item.children.length) {
        return { children: resolveRoutes(item.children, permissions) };
      }
      return {};
    };
    return {
      ...item,
      ...childrenList()
    };
  })
  .filter(Boolean);

export {
  resolveRoutes
};
