/*
 * @Date: 2020-12-21 09:42:40
 * @Description: file content
 */
import Vue from 'vue';
import Router from 'vue-router';

import i18n from '@/locales';
import store from '@/store';
import { setDocumentTitle } from '@/utils/helpers/dom';

/*
 * @Date: 2020-06-14 10:51:50
 * @Description: import and init visit track
 */
// import Tracker from '@babylon/visit-track';
// const tracker = new Tracker();

// hack router push callback
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject);
  }
  return originalPush.call(this, location).catch((err) => err);
};

Vue.use(Router);

const router = new Router({
  mode: 'hash',
  routes: []
});

router.beforeEach((to, from, next) => {
  // console.log(`${to}${from}${next}`);

  // NProgress.start() // start progress bar
  // eslint-disable-next-line no-unused-expressions
  to.name && setDocumentTitle(`menu.${i18n.t(to.name)}`, process.env.VUE_APP_TITLE);
  if (store.state.user.info.uid == null || store.state.user.info.uid === '') {
    // request login userInfo
    store.dispatch('user/getInfo').then(() => {
      // generate dynamic router
      store.dispatch('menu/initMenus').then(() => {
        // 根据roles权限生成可访问的路由表
        // 动态添加可访问路由表
        // router.addRoutes(store.getters.addRouters)
        // 请求带有 redirect 重定向时，登录自动重定向到该地址
        let redirect = decodeURIComponent(from.query.redirect || to.path);
        // if(redirect.indexOf("access_token") > 0){
        //   redirect = "/"
        // }
        if (to.path === redirect) {
          // set the replace: true so the navigation will not leave a history record
          if(to.path.includes('access_token')){
            next({ path: localStorage.getItem('hrd_url') });
          }else{
              next({ ...to, replace: true });
          }
        } else {
          // 跳转到目的路由
          next({ path: redirect });
        }
      });
    });
  } else if (!to.name && to.matched.length === 0) {
    if (to.path === '/404') {
      // eslint-disable-next-line no-alert
      alert('you have no right to access page 404');
      // eslint-disable-next-line no-restricted-globals
      history.back();
      return;
    }
    next({ path: '/404', replace: true });
  } else {
    next();
  }
});

// router.afterEach((to, from) => {})
export default router;
