import T from '@babylon/design/es/table/Table';
import _ from 'lodash';

export default {
  data() {
    return {
      needTotalList: [],
      selectedRows: [],
      selectedRowKeys: [],
      localLoading: false,
      localDataSource: [],
      localPagination: { ...this.pagination },
      count: 0
    };
  },
  props: {
    ...T.props, // list数据
    tableData: {
      type: [Object, Array],
      required: null
    },
    rowKey: {
      type: [String, Function],
      default: 'key'
    },
    data: {
      type: Function
      // required: true
    },
    pageNum: {
      type: Number,
      default: 1
    },
    pageSize: {
      type: Number,
      default: 10
    },
    showSizeChanger: {
      type: Boolean,
      default: true
    },
    size: {
      type: String,
      default: 'default'
    },
    /**
         * alert: {
         *   show: true,
         *   clear: Function
         * }
         */
    alert: {
      type: [Object, Boolean],
      default: null
    },
    rowSelection: {
      type: Object,
      default: null
    },
    /** @Deprecated */
    showAlertInfo: {
      type: Boolean,
      default: false
    },
    showPagination: {
      type: String | Boolean,
      default: 'auto'
    },
    /**
         * enable page URI mode
         *
         * e.g:
         * /users/1
         * /users/2
         * /users/3?queryParam=test
         * ...
         */
    pageURI: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    localPagination: {
      handler(newVal, oldVal) {
        if (newVal.current !== oldVal.current) {
          if (this.pageURI) {
            this.$router.push({
              ...this.$route,
              name: this.$route.name,
              params: { ...this.$route.params, pageNo: newVal.current }
            });
          }
          // change pagination, reset total data
          this.needTotalList = this.initTotalList(this.columns);
          this.selectedRowKeys = [];
          this.selectedRows = [];
        }
      },
      deep: true
    },
    pageNum(val) {
      Object.assign(this.localPagination, {
        current: val
      });
    },
    pageSize(val) {
      Object.assign(this.localPagination, {
        pageSize: val
      });
    },
    showSizeChanger(val) {
      Object.assign(this.localPagination, {
        showSizeChanger: val
      });
    },
    tableData(val) {
      if (val && val.length) {
        this.loadData();
      }
    }
  },
  created() {
    const { pageNo } = this.$route.params;
    const localPageNum = (this.pageURI && pageNo && parseInt(pageNo, 10)) || this.pageNum;
    this.localPagination = (['auto', true].includes(this.showPagination)
                && ({
                  ...this.localPagination,
                  current: localPageNum,
                  pageSize: this.pageSize,
                  showSizeChanger: this.showSizeChanger
                }))
            || false;
    this.needTotalList = this.initTotalList(this.columns);
    this.loadData();
  },
  methods: {
    // 处理分页总数
    h(a, b, c) {
      return this.$createElement(a, b, c);
    },
    dealTotalPage(total) {
      return this.h(
        'span',
        {
          class: {
            'total-page': true
          }
        },
        [
          this.$t('label.total'),
          this.h(
            'a-tag',
            {
              class: {
                'total-tags': true
              }
            },
            total
          ),
          this.$t('label.item')
        ]
      );
    },
    /**
         * 表格重新加载方法
         * 如果参数为 true, 则强制刷新到第一页
         * @param Boolean bool
         */
    refresh(bool = false) {
      if (bool) {
        this.localPagination = {

          current: 1,
          pageSize: this.pageSize
        };
      }
      this.loadData();
    },
    /**
         * 加载数据方法
         * @param {Object} pagination 分页选项器
         * @param {Object} filters 过滤条件
         * @param {Object} sorter 排序条件
         */
    loadData(filters, sorter, pagination = {}) {
      this.localLoading = true;
      let pageNo = this.pageNum;
      if (this.showPagination && this.localPagination.current) {
        pageNo = this.localPagination.current;
      }
      if (pagination.current) {
        pageNo = pagination.current;
      }
      let { pageSize } = this;
      if (this.showPagination && this.localPagination.pageSize) {
        pageSize = this.localPagination.pageSize;
      }
      if (pagination.pageSize) {
        pageSize = pagination.pageSize;
      }
      const parameter = {
        pageNo,
        pageSize,
        ...(sorter
                    && sorter.field && {
          sortField: sorter.field
        })
                    || {},
        ...(sorter
                    && sorter.order && {
          sortAsc: sorter.order === 'ascend'
        })
                    || {},
        ...filters
      };
      if (this.tableData && this.tableData.length) {
        this.localDataSource = this.tableData; // 返回结果中的数组数据
        this.localLoading = false;
        this.count = this.tableData.length;
      } else {
        if (!this.data) {
          this.localLoading = false;
          return;
        }
        const result = this.data(parameter);
        // 对接自己的通用数据接口需要修改下方代码中的 r.pageNo, r.totalCount, r.data
        // eslint-disable-next-line
                if (
          (typeof result === 'object' || typeof result === 'function')
                    && typeof result.then === 'function'
        ) {
          result.then((r) => {
            this.localPagination = (this.showPagination
                                && ({
                                  ...this.localPagination,
                                  current: () => {
                                    if (r) {
                                      return Number(r.pageNo);
                                    }
                                    return 1;
                                  }, // 返回结果中的当前分页数
                                  total: () => {
                                    if (r) {
                                      return Number(r.total);
                                    }
                                    return 0;
                                  }, // 返回结果中的总记录数
                                  showTotal: () => {
                                    if (r) {
                                      return this.dealTotalPage(r.total);
                                    }
                                    return this.dealTotalPage(null);
                                  },
                                  showSizeChanger: this.showSizeChanger,
                                  pageSize:
                                        (pagination && pagination.pageSize)
                                        || this.localPagination.pageSize
                                }))
                            || false;
            // 为防止删除数据后导致页面当前页面数据长度为 0 ,自动翻页到上一页

            const ruleR = !!(r && r.data && r.data.length === 0);
            if (
              ruleR && this.showPagination
                            && this.localPagination.current > 1
            ) {
              this.localPagination.current -= 1;
              this.loadData();
              return;
            }

            // 这里用于判断接口是否有返回 r.totalCount 且 this.showPagination = true
            // 且 pageNo 和 pageSize 存在 且 totalCount 小于等于 pageNo * pageSize 的大小
            // 当情况满足时，表示数据不满足分页大小，关闭 table 分页功能
            try {
              if (
                r
                                && ['auto', true].includes(this.showPagination)
                                && r.totalCount <= r.pageNo * this.localPagination.pageSize
              ) {
                this.localPagination.hideOnSinglePage = true;
              }
            } catch (e) {
              this.localPagination = false;
            }
            if (r) {
              this.localDataSource = r.data;
            } else {
              this.localDataSource = [];
            }
            this.localLoading = false;
          });
        }
      }
    },
    initTotalList(columns) {
      const totalList = [];
      if (columns && columns instanceof Array) {
        columns.forEach((column) => {
          if (column.needTotal) {
            totalList.push({
              ...column,
              total: 0
            });
          }
        });
      }
      return totalList;
    },
    /**
         * 用于更新已选中的列表数据 total 统计
         * @param selectedRowKeys
         * @param selectedRows
         */
    updateSelect(selectedRowKeys, selectedRows) {
      this.selectedRows = selectedRows;
      this.selectedRowKeys = selectedRowKeys;
      const list = this.needTotalList;
      this.needTotalList = list.map((item) => ({
        ...item,
        total: selectedRows.reduce((sum, val) => {
          // eslint-disable-next-line no-undef
          const total = sum + parseInt(_.get(val, item.dataIndex), 10);
          if (Number.isNaN(total)) {
            return 0;
          }
          return total;
        }, 0)
      }));
    },
    /**
         * 清空 table 已选中项
         */
    clearSelected() {
      if (this.rowSelection) {
        this.rowSelection.onChange([], []);
        this.updateSelect([], []);
      }
    },
    /**
         * 处理交给 table 使用者去处理 clear 事件时，内部选中统计同时调用
         * @param callback
         * @returns {*}
         */
    renderClear(callback) {
      if (this.selectedRowKeys.length > 0) {
        return (
                    <a

                        style="margin-left: 24px"
                        onClick={() => {
                          callback();
                          this.clearSelected();
                        }}
                    >
                        清空
                    </a>
        );
      }
      return null;
    },
    renderAlert() {
      // 绘制统计列数据
      const needTotalItems = this.needTotalList.map((item) => {
        const totalRender = () => {
          if (!item.customRender) {
            return item.total;
          }
          return item.customRender(item.total);
        };
        return (
                    <span style="margin-right: 12px">
                        {item.title}总计{' '}
                        <a style="font-weight: 600">
                            { totalRender() }
                        </a>
                    </span>
        );
      });

      // 绘制 清空 按钮
      let clearItem = null;
      if (typeof this.alert.clear === 'boolean' && this.alert.clear) {
        clearItem = this.renderClear(this.clearSelected);
      }
      if (this.alert !== null && typeof this.alert.clear === 'function') {
        clearItem = this.renderClear(this.alert.clear);
      }
      // 绘制 alert 组件
      return (
                <a-alert showIcon={true} style="margin-bottom: 16px">
                    <template slot="message">
                        <span style="margin-right: 12px">
                            已选择: <a style="font-weight: 600">{this.selectedRows.length}</a>
                        </span>
                        {needTotalItems}
                        {clearItem}
                    </template>
                </a-alert>
      );
    }
  },
  render() {
    const props = {};
    const localKeys = Object.keys(this.$data);
    const alertRule = (typeof this.alert === 'object'
            && this.alert !== null
            && this.alert.show);
    const showAlert = (alertRule
                && typeof this.rowSelection.selectedRowKeys !== 'undefined')
            || this.alert;

    Object.keys(T.props).forEach((k) => {
      const localKey = `local${k.substring(0, 1).toUpperCase()}${k.substring(1)}`;
      if (localKeys.includes(localKey)) {
        props[k] = this[localKey];
        return props[k];
      }
      if (k === 'rowSelection') {
        if (showAlert && this.rowSelection) {
          // 如果需要使用alert，则重新绑定 rowSelection 事件
          props[k] = {
            ...this.rowSelection,
            selectedRows: this.selectedRows,
            selectedRowKeys: this.selectedRowKeys,
            onChange: (selectedRowKeys, selectedRows) => {
              this.updateSelect(selectedRowKeys, selectedRows);
              if (typeof this[k].onChange !== 'undefined') {
                this[k].onChange(selectedRowKeys, selectedRows);
              }
            }
          };
          return props[k];
        }
        if (!this.rowSelection) {
          // 如果没打算开启 rowSelection 则清空默认的选择项
          props[k] = null;
          return props[k];
        }
      }
      if (this[k]) {
        props[k] = this[k];
      }
      return props[k];
    });
    const table = (
            <a-table
                {...{ props, scopedSlots: { ...this.$scopedSlots } }}
                class={`${props.class} bmw-primary-table`}
                onChange={this.loadData}
                onExpand={(expanded, record) => {
                  this.$emit('expand', expanded, record);
                }}
            >
                {Object.keys(this.$slots).map((name) => (
                    <template slot={name}>{this.$slots[name]}</template>
                ))}
            </a-table>
    );
    const count = (
            <div style="text-align: right;padding: 10px;">
                共 <b>{this.count}</b> 条
            </div>
    );
    const alertRender = () => {
      if (showAlert) {
        return this.renderAlert();
      }
      return null;
    };
    const countRender = () => {
      if (this.count > 0) {
        return count;
      }
      return null;
    };
    return (
            <div class="table-wrapper">
                {alertRender()}
                {table}
                {countRender()}
            </div>
    );
  }
};
