/*
 * @Author: Yang Yang
 * @Date: 2020-12-21 09:42:40
 * @Description: file content
 */
import Vue from 'vue';
import store from '@/store';

/**
 * Auth 权限指令
 * 指令用法：
 *  - 在需要控制 auth 级别权限的组件上使用 v-auth:[method] , 如下：
 *    <i-button v-auth="'audit.query'" >添加用户</a-button>
 *    <a-button v-auth="experssion">删除用户</a-button>
 *
 *  - 当前用户没有权限时，组件上使用了该指令则会被隐藏
 */
const auth = Vue.directive('auth', {
  inserted(el, binding) {
    const actionName = binding.value || '';
    const permissions = store.getters['user/permissions'];
    const [requiredPermission, requiredAction] = actionName.split('.');
    if (
      !(
        permissions[requiredPermission]
                && requiredAction
                && permissions[requiredPermission].includes(requiredAction)
      )
    ) {
      if (el.parentNode) {
        el.parentNode.removeChild(el);
      } else {
        // eslint-disable-next-line no-param-reassign
        el.style.display = 'none';
      }
    }
  }
});

export default auth;
