<template>
    <base-layout class="top-side-layout">
        <template #header>
            <div class="top-side-nav-container">
                <div class="logo" style="width: 420px">
                    <img :src="logo" width="24" alt="Logo"/>
                    <div class="title" :style="{ fontSize }">{{ title }}</div>
                </div>
<!--                <a-menu-->
<!--                    mode="horizontal"-->
<!--                    class="top-menu-menus"-->
<!--                    :inlineIndent="10"-->
<!--                    :selectedKeys="selectedKeys"-->
<!--                    @click="click4menu"-->
<!--                >-->
<!--                    <a-menu-item :key="route.path" v-for="route in showMenu">-->
<!--                        <div class="menuItem">-->
<!--                            {{ $t(`menu.${route.name}`) }}-->
<!--                        </div>-->
<!--                    </a-menu-item>-->
<!--                </a-menu>-->
                <slot name="header-right"></slot>
            </div>
        </template>
        <template #default>
            <div class="side-menu-container" v-show="submenus.length">
                <menus
                    mode="inline"
                    class="sidebar-menus"
                    :inline-indent="16"
                    :inline-collapsed="collapsed"
                    :style="{ width: !collapsed ? '240px' : '48px' }"
                    :selectedKeys.sync="selectedKeys"
                    :openKeys.sync="openKeys"
                    :menus="submenus"
                    @click="click4menu"
                ></menus>
                <div class="siderbar-collapsed">
                  <div class="toggle-btn"
                       @click="toggle"
                       :style="{ transform: `rotateZ(${!collapsed ? 0 : 180}deg)` }">
                      <b-icon name="left"
                            size="16px"
                            color="#fff"
                      ></b-icon>
                  </div>
                </div>
            </div>
            <div class="container">
                <slot></slot>
            </div>
        </template>
    </base-layout>
</template>

<script>
import layout from '../../mixin/layout.mix';
import BaseLayout from '../../components/BaseLayout/index.vue';

export default {
  name: 'top-side-menu-layout',
  mixins: [layout],
  computed: {
    submenus() {
      const submenus = this.menus.find((item) => item.path === this.selectedKeys[0]);
      if (submenus.children) {
        return submenus.children;
      }
      return [];
    },
    fontSize() {
      const titleLength = 10;
      if (this.title.length >= titleLength) {
        return '14px';
      }
      return '20px';
    },
    showMenu() {
      return this.menus.filter((item) => !item.hidden);
    }
  },
  components: {
    [BaseLayout.name]: BaseLayout
  }
};

</script>

<style lang="less" scoped>
@import 'index.less';
.toggle-btn{
    cursor: pointer;
}
//.sidebar-menus{
//    height: calc(100vh - 130px);
//    overflow-x: hidden;
//    overflow-y: overlay;
//    &::-webkit-scrollbar {
//        width: 16px;
//        //height: 8px;
//    }
//    &::-webkit-scrollbar-thumb {
//        border-radius: 12px;
//        //-webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
//        //background: rgba(0,0,0,0.2);
//        border: 6px solid rgba(0, 0, 0, 0);
//        box-shadow: 8px 0 0 #a5adb7 inset;
//        &:hover {
//            box-shadow: 8px 0 0 #8e9bac inset;
//        }
//    }
//    &::-webkit-scrollbar-track {
//        border-radius: 0;
//        background: transparent;
//    }
//}
</style>
