<!--/**
 * @name 'index' build by Zhou jinhua in june 19
 * from api get data
 */<-->
<template>
    <a-form-model-item
        :label="label"
        :prop="dataIndex"
        :labelCol="labelCol"
        :wrapperCol="wrapperCol"
    >
        <!--  -->
        <a-auto-complete
            v-model="formObj[dataIndex]"
            style="width: 80%"
            :allowClear="dConfig.allowClear"
            :placeholder="placeholder"
            :disabled="disabled"
            option-label-prop="title"
            @select="handleSelect"
            @blur="handleBlurClear"
            @focus="handleFocusClear"
            @change="handleChange"
            :title="formObj[dataIndex] ? formObj[dataIndex] : placeholder"
            :open="isOpen"
        >
            <template slot="dataSource" v-if="dConfig.isArr">
                <a-select-option
                    v-for="item in sData"
                    :key="
                        dConfig.id ? item.id : dConfig.shCode ? item[valName[0]] : item[valName[1]]
                    "
                    :title="dConfig.shCode ? item[valName[0]] : item[valName[1]]"
                    class="vendor-search"
                >
                    <span>{{ item[valName[0]] }}</span>
                    <span v-if="valName[1]" class="auto_li_desct">{{ item[valName[1]] }}</span>
                    <span v-if="valName[2]" class="auto_li_desct">{{ item[valName[2]] }}</span>
                </a-select-option>
            </template>
            <a-input class="aa_input_search">
                <a-button
                    slot="suffix"
                    class="search-btn input_search_btn"
                    type="primary"
                    :disabled="disabled"
                    @click="handleSearch"
                >
                    <a-icon type="search" />
                </a-button>
            </a-input>
        </a-auto-complete>
    </a-form-model-item>
</template>

<script>
import store from '@/store';

export default {
  name: 'CommonAutoComplete',
  components: {},
  data() {
    return {
      loading: false,
      sData: [],
      pholder: this.$t('message.inputSearch'),
      dConfig: {
        isArr: true, // 返回值是否是数组
        shCode: false, // 默认显示供应商名称
        allowClear: false, // 显示清除按钮
        defaultActiveFirstOption: false, // 是否高亮第一行
        notFoundContent: 'Not Found', // 当下拉列表为空时显示的内容,
        valLenth: 0 // 不选择，直接输入时，正确数字位数
      },
      selectOne: null,
      selectObj: {},
      isOpen: false,
      title: null,
      formObj: this.form
    };
  },
  model: {
    event: 'onChange',
    prop: 'value'
  },
  props: {
    // 数据代理api
    fetchApi: Function,
    // 接口参数
    param: {},
    value: {
      type: [String, Number]
    },
    config: {
      type: Object,
      default: () => ({})
    },
    // 设置显示字段
    valName: {
      type: [Object, Array],
      default: () => ['id', 'name']
    },
    // 设置搜索字段
    searchArr: {
      type: [Object, Array],
      default: () => []
    },
    form: { required: true, type: Object }, // 表单域（必传）
    dataIndex: { required: true, type: String }, // 绑定值（必传）
    label: { type: String, default: '' }, // 表单项标签名
    disabled: { type: Boolean, default: false }, // 是否禁用
    placeholder: { type: String, default: '' },
    labelCol: { type: Object, default: null },
    wrapperCol: { type: Object, default: null },
    vCode: { type: Boolean, default: false }
  },
  watch: {
    placeholder: {
      handler(val) {
        this.pholder = val;
      },
      immediate: true
    },
    form: {
      handler(val) {
        this.formObj = val;
        if (val[this.dataIndex] && !this.disabled && !val.id && !this.vCode) {
          this.handleSearch(this.formObj[this.dataIndex]);
        }
      },
      immediate: true
    }
  },
  computed: {
    options() {
      return store.getters.options;
    }
  },
  mounted() {
    Object.assign(this.dConfig, this.config);
    this.title = null;
  },
  methods: {
    handleFocusClear() {
      this.sData = [];
      if (!this.formObj[this.dataIndex]) {
        this.selectOne = null;
      }
    },
    handleBlurClear() {
      if (!this.selectOne) {
        this.sData = [];
        if (this.dConfig.valLenth > 0
            && this.formObj[this.dataIndex].length !== this.dConfig.valLenth) {
          this.formObj[this.dataIndex] = null;
          this.$emit('submit', this.formObj);
        }
      }
      this.handleChange();
      this.isOpen = false;
      // this.formObj[this.dataIndex] =
      // this.formObj[this.dataIndex] ?
      // this.selectObj ? this.selectObj[this.valName[0]] : null
      // : null;
      if (this.formObj[this.dataIndex] && this.selectObj) {
        this.formObj[this.dataIndex] = this.selectObj[this.valName[0]];
      } else {
        this.formObj[this.dataIndex] = null;
      }
      this.$emit('submit', this.formObj);
      this.title = this.formObj[this.dataIndex];
    },
    init() {
      store
        .dispatch('common/getSelectOptions', {
          fetchApi: this.fetchApi,
          param: this.param,
          noPage: this.dConfig.noPage
        })
        .then(() => {
          // console.log('dict'.res);
        });
    },
    sssd() {
      this.handleSearch();
    },
    // 远程获取接口---点击按钮查询
    handleSearch() {
      const that = this;
      const value = that.formObj[that.dataIndex];
      if (that.dConfig.beforeRule && value && value.length > 1) {
        const end = 2;
        const twoUid = value.substring(0, end);
        if ((twoUid === 'UF' || twoUid === 'uf') && !that.dConfig.id) {
          this.$message.error(this.$t('message.uid'));
          return;
        }
      }
      if (value) {
        that.loading = true;
        const setP = {};
        Object.keys(this.searchArr).forEach((i) => {
          if (this.title) {
            setP[this.searchArr[i]] = this.title;
          } else {
            setP[this.searchArr[i]] = value;
          }
        });
        // console.log('value', setP);
        const params = Object.assign(setP, that.param);
        store
          .dispatch('common/getSelectOptions', {
            fetchApi: that.fetchApi,
            param: params
          })
          .then((res) => {
            this.isOpen = true;
            that.loading = false;
            if (that.dConfig.isArr) {
              this.sData = res || [];
            } else {
              this.sData = [res];
            }
            // =  ? res || [] : [res];
            if (!that.dConfig.isArr && res) {
              this.$emit('onChange', res, value);
            }
          })
          .catch(() => {
            // console.info('error', res);
            that.loading = false;
          });
      } else {
        this.sData = [];
      }
    },
    handleSelect(value) {
      this.isOpen = false;
      let v = this.sData.filter(
        (x) => x[this.valName[0]] === value || x[this.valName[1]] === value
      );
      if (this.dConfig.id) {
        v = this.sData.filter((x) => x.id === value);
      }
      // eslint-disable-next-line prefer-destructuring
      this.selectOne = v[0];
      // eslint-disable-next-line prefer-destructuring
      this.selectObj = v[0];
      // console.log('v0', v[0], value, this.dConfig.id);
      // this.selectObj = v[0];
      this.$emit('onChange', v[0] || {}, value);
      this.title = v[0][this.valName[0]];
    },
    handleChange() {
      this.isOpen = false;
      const value = this.formObj[this.dataIndex];
      const params = { code: value, ...this.selectOne };
      const formObjLength = 19;
      if (
        this.title
                && this.title !== this.formObj[this.dataIndex]
                && this.formObj[this.dataIndex]
                && this.formObj[this.dataIndex].length < formObjLength
      ) {
        this.title = this.formObj[this.dataIndex];
      }
      if (
        this.selectObj[this.valName[0]] === this.formObj[this.dataIndex]
                || this.sData.length === 0
      ) {
        this.$emit('onChange', params, null);
      }
    }
  }
};
</script>
<style>
/* .vendor-search .ant-form-item-control-wrapper{
  min-width: 270px;
} */
.form-alignment-m .ant-form-item-control-wrapper {
    /* width: 200px; */
    min-width: 200px;
}

.aa_input_search .ant-input-suffix {
    right: -32px !important;
}

.aa_input_search .ant-btn:not(.ant-btn-link)[disabled] {
    border-color: #bebfc1;
}
</style>
