import { getInfo } from '@/api/system/rbac';

export default {
  namespaced: true,
  state: {
    info: {}
  },
  getters: {
    permissions(state) {
      const result = {};
      (state.info.permissions || []).forEach((element) => {
        result[element.permission] = element.actions;
      });
      return result;
    },
    getCurrentUserInfo(state) {
      return state.info.uid;
    }
  },
  actions: {
    async getInfo({ commit }) {
      // eslint-disable-next-line no-useless-catch
      try {
        const result = await getInfo();
        commit('setUserInfo', result);
        return result;
      } catch (error) {
        // console.log(error);
        throw error;
      }
    }
  },
  mutations: {
    setUserInfo(state, data) {
      state.info = data;
    }
  }
};
