<template>
    <div class="pro-layout">
        <div class="pro-layout-header" v-if="path!=='/home' && path!=='/COIEvaluationReport'">
            <slot name="header"></slot>
        </div>
        <div class="pro-layout-body" :style="path==='/home'?'height:100vh':''">
            <slot></slot>
            <slot name="footer"></slot>
        </div>
    </div>
</template>

<script>
export default {
  name: 'base-layout',
  computed: {
    path() {
      return this.$route.path;
    }
  }
};
</script>

<style lang="less" scoped>
.pro-layout {
    display: flex;
    height: 100vh;
    background-image: url("../../assets/img/bg-light.png");
    background-size: 100%;
    background-repeat: no-repeat;
    //min-width: 1440px;
}
.pro-layout-header {
    display: flex;
}
</style>
