import request from '@/utils/request/index';
// 发起列表
export const getInitiationList = (params) => request({
  url: '/initation-records/page',
  method: 'GET',
  params
});
// 删除发起记录
export const deleteInitiation = (id) => request({
  url: `/initation-records/${id}`,
  method: 'DELETE'
});
// 签署列表
export const getSigningList = (params) => request({
  url: '/sign-user-details/signingRecordPage',
  method: 'POST',
  data: params
});
// revoke
export const revokeSigning = (params) => request({
  url: '/sign-user-details/documentRevoke',
  method: 'POST',
  data: params
});
// 签署记录--查看详情
export const detailSigning = (params) => request({
  url: '/sign-user-details/documentPreview',
  method: 'GET',
  params
});
// 签署列表
export const getCoiReportList = (params) => request({
  url: '/coiApp/report/pageList',
  method: 'GET',
  params
});
// 签署导出
export const getCoiReportListDownload = (params) => request({
  url: '/coiApp/report/export',
  method: 'GET',
  responseType: 'blob',
  params
});
// 获取提交人列表
export const getSubmitterList = () => request({
  url: '/initation-records/searchSubmitters',
  method: 'POST'
});
