<template>
    <b-layout
        :type="LAYOUT_TYPES.TOP_SIDE"
        :logo="logo"
        :menus="menus"
        :title="title"
        :i18nRender="i18nRender"
        :activeMenus="activeMenus"
    >
        <router-view-custom :alive="true" />
        <template #header-right>
            <right-header />
        </template>
    </b-layout>
</template>

<script>
import logo from '@/assets/icons/BMW_Grey-Colour_RGB.svg';

import i18n from '@/locales';
// eslint-disable-next-line import/namespace,import/default
import RouterView from './RouteView';
import Layout, { LAYOUT_TYPES } from './index';
import RightHeader from './components/RightHeader/index.vue';

export default {
  name: 'LayoutDefault',
  components: {
    [Layout.name]: Layout,
    [RouterView.name]: RouterView,
    [RightHeader.name]: RightHeader
  },
  data() {
    return {
      logo,
      LAYOUT_TYPES,
      title: process.env.VUE_APP_TITLE,
      i18nRender: (k) => i18n.t(k)
    };
  },
  computed: {
    menus() {
      return this.$store.state.menu.menus[0].children;
    },
    activeMenus() {
      if (this.$route.matched.slice(1)) {
        return this.$route.matched.slice(1).map((item) => item.path);
      }
      return [];
    }
  }
};
</script>
