import { notification } from '@babylon/design';

export default Object.keys(notification).reduce(
  (acc, key) => ({
    ...acc,
    [key]: (config) => notification[key]({
      ...config,
      class: `ant-notification-notice-${key}`
    })
  }),
  // eslint-disable-next-line no-unused-vars
  {}
);
