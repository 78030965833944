// 调试API
import request from '@/utils/request/index';

export const getInfo = () => request({
  url: '/users/current-user',
  method: 'GET'
});

export const getUserList = (parameter) => request({
  url: '/users/page',
  method: 'GET',
  params: parameter
});

export const getUserById = (id) => request({
  url: `/users/${id}`,
  method: 'GET'
});

export const addUser = (params) => request({
  url: '/users',
  method: 'POST',
  data: params
});

export const updateUser = (id, params) => request({
  url: `/users/${id}`,
  method: 'PUT',
  data: params
});

export const deleteUser = (id) => request({
  url: `/users/${id}`,
  method: 'DELETE'
});

export const getRoleList = (parameter) => request({
  url: '/roles/page',
  method: 'GET',
  params: parameter
});

export const listAvailableRoles = () => request({
  url: '/roles',
  method: 'GET',
  params: {
    status: 'Enabled'
  }
});

export const getRoleById = (id) => request({
  url: `/roles/${id}`,
  method: 'GET'
});

export const listApiPermissions = () => request({
  url: '/permissions/api',
  method: 'GET'
});

export const listDataPermissions = () => request({
  url: '/permissions/data',
  method: 'GET'
});

export const synchronizePermissions = () => request({
  url: '/permissions/sync',
  method: 'POST'
});

export const addRole = (params) => request({
  url: '/roles',
  method: 'POST',
  data: params
});

export const updateRole = (roleId, params) => request({
  url: `/roles/${roleId}`,
  method: 'PUT',
  data: params
});

export const deleteRole = (roleId) => request({
  url: `/roles/${roleId}`,
  method: 'DELETE'
});

export const listGlobalRoleUserTypes = () => request({
  url: '/roles/user-types',
  method: 'GET'
});

export const pagePermissions = (parameter) => request({
  url: '/permissions/page',
  method: 'GET',
  params: parameter
});

export const pageDataPermissions = (parameter) => request({
  url: '/permissions/data/page',
  method: 'GET',
  params: parameter
});

/** 根据id查看权限 */
export const getById = (id) => request({
  url: `/permissions/${id}`,
  method: 'GET'
});

/** 根据id删除权限 */
export const deleteById = (id) => request({
  url: `/permissions/${id}`,
  method: 'DELETE'
});

/** 编辑权限 */
export const updateById = (id, params) => request({
  url: `/permissions/${id}`,
  method: 'PUT',
  data: params
});

/** 添加权限 */
export const save = (params) => request({
  url: '/permissions',
  method: 'POST',
  data: params
});

/* 获取单个部门Id */
export const getDepartmentById = (id) => request({
  url: `/departments/${id}`,
  method: 'GET'
});

/* 新增部门信息 */
export const saveDepartment = (params) => request({
  url: '/departments',
  method: 'POST',
  data: params
});

/* 删除一个部门 */
export const deleteDepartment = (id) => request({
  url: `/departments/${id}`,
  method: 'DELETE'
});

/* 更新一个部门 */
export const updateDepartment = (id, params) => request({
  url: `/departments/${id}`,
  method: 'PUT',
  data: params
});

/* 获取部门树 */
export const getDepartmentTree = (params) => request({
  url: '/departments/list',
  method: 'GET',
  data: params
});
// 获取OWner
export const getOwners = (params) => request({
  url: '/users/owners',
  method: 'GET',
  params
});
