import { mapState } from 'vuex';

const i18nMixin = {
  computed: {
    ...mapState({
      currentLang: (state) => state.lang
    })
  },
  methods: {
    setLang(lang) {
      this.$store.dispatch('setLang', lang, { root: true });
    }
  }
};

export default i18nMixin;
