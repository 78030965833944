<template>
    <a-result class="exception-404" status="404" :sub-title="$t('message.notfound')">
        <template #extra>
            <a-button type="primary" @click="toHome">
                {{ $t('message.backHome') }}
            </a-button>
        </template>
    </a-result>
</template>

<script>
export default {
  name: 'Exception404Page',
  methods: {
    toHome() {
      const keys = Object.keys(this.$store.getters['user/permissions']);
      const value = Object.values(this.$store.getters['user/permissions']);
      let index = '/';
      if (keys[0] === 'Role' && value[0].indexOf('search') > -1) {
        index = '/system/rbac/role-list';
      }
      if (keys[0] === 'Template Management' && value[0].indexOf('search') >= 0) {
        index = '/docManagement/template/index';
      }
      if (keys[0] === 'Personnel Change' && value[0].indexOf('search') > -1) {
        index = '/report/personnelChange/index';
      }
      if (keys[0] === 'User' && value[0].indexOf('search') >= 0) {
        index = '/system/rbac/user-list';
      }
      if (keys[0] === 'Permission' && value[0].indexOf('search') > -1) {
        index = '/system/rbac/permission-list';
      }
      if (keys[0] === 'Package Management' && value[0].indexOf('search') >= 0) {
        index = '/docManagement/package/index';
      }
      if (keys[0] === 'Signing Record' && value[0].indexOf('search') > -1) {
        index = '/reporting/signingRecord/index';
      }
      if (keys[0] === 'COI Record' && value[0].indexOf('search') >= 0) {
        index = '/COI/ToDo/index';
      }
      if (keys[0] === 'Initiation Record' && value[0].indexOf('search') > -1) {
        index = '/reporting/initiationRecord/index';
      }
      if (keys[0] === 'To-Do Task' && value[0].indexOf('search') >= 0) {
        index = '/reporting/To-Do/index';
      }
      this.$router.push({ path: index });
    }
  }
};
</script>
<style lang="less" scoped>
.exception-404 {
    padding-top: 100px;
}
</style>
