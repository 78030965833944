export default {
  namespaced: true,
  state: {
    options: []
  },
  getters: {},
  actions: {
    getSelectOptions({ commit }, {
      fetchData, fetchApi, param, page
    }) {
      if (fetchApi) {
        return new Promise((resolve, reject) => {
          fetchApi()(param)
            .then((response) => {
              let options = response;
              if (page) {
                options = response.data;
              }
              commit('getOptions', options);
              resolve(options);
            })
            .catch((error) => {
              reject(error);
            });
        });
      }
      commit('getOptions', fetchData);
      return fetchData;
    }
  },
  mutations: {
    getOptions(state, options) {
      state.options = options;
    }
  }
};
