export default {
  namespaced: true,
  state: {
    count: 0
  },
  mutations: {
    receive(state, payload) {
      Object.keys(payload).forEach((key) => {
        if (Object.prototype.hasOwnProperty.call(payload, key)) {
          state[key] = payload[key];
        }
      });
    },
    receiveCount(state, payload) {
      state.count = payload;
    }
  }
};
