/*
 * @Description: static routes
 */

//! If unnecessary, please don't set name in root, otherwise it will be shown in breadcrumb.

const routes = [{
  root: {},
  meta: {
    alive: true
  },
  path: '/',
  hidden: false,
  redirect: '/reporting/initiationRecord/index',
  component: 'ProLayout',
  permissions: [],
  children: [
    {
      name: 'home',
      meta: {
        icon: {
          type: 'home',
          theme: 'outlined'
        },
        module: 'System',
        function: 'Home Page'
      },
      path: '/home',
      hidden: false,
      component: 'home/index.vue',
      permissions: []
    },
    {
      name: 'hrdsms',
      meta: {
        icon: {
          type: 'setting',
          theme: 'outlined'
        },
        alive: true
      },
      path: '/system',
      hidden: false,
      redirect: '/system/rbac',
      component: 'RouteView',
      permissions: [],
      children: [
        {
          name: 'signingRecord',
          meta: {
            icon: require('../assets/icons/signing.svg'),
            alive: true
          },
          path: '/reporting/signingRecord',
          hidden: false,
          redirect: '/reporting/signingRecord/index',
          component: 'RouteView',
          permissions: ['Initiation Record.query', 'To-Do Task.query', 'Signing Record.query'],
          children: [
            {
              name: 'signingRecord.initiation',
              meta: {
                module: 'docManagement',
                function: 'Package Management'
              },
              path: '/reporting/initiationRecord/index',
              hidden: false,
              component: 'reporting/initiationRecord/index.vue',
              permissions: ['Initiation Record.query']
            },
            {
              name: 'todo',
              meta: {
                module: 'docManagement',
                function: 'template management'
              },
              path: '/reporting/To-Do/index',
              hidden: false,
              component: 'reporting/To-Do/index.vue',
              permissions: ['To-Do Task.query']
            },
            {
              name: 'signingRecord.record',
              meta: {
                module: 'docManagement',
                function: 'template management'
              },
              path: '/reporting/signingRecord/index',
              hidden: false,
              component: 'reporting/signingRecord/index.vue',
              permissions: ['Signing Record.query']
            },
            // 发起签署
            {
              name: 'docManagement.initiation',
              meta: {
                module: 'docManagement',
                function: 'Initiation Record'
              },
              path: '/docManagement/initiation/index',
              hidden: true,
              component: 'docManagement/initiation/index.vue'
              // permissions: ['user.query']
            }
          ]
        },
        {
          name: 'docManagement',
          meta: {
            icon: require('../assets/icons/doc.svg'),
            alive: true
          },
          path: '/docManagement/template',
          hidden: false,
          redirect: '/docManagement/template/index',
          component: 'RouteView',
          permissions: ['Template Management.query', 'Package Management.query'],
          children: [
            {
              name: 'docManagement.template',
              meta: {
                module: 'docManagement',
                function: 'template management'
              },
              path: '/docManagement/template/index',
              hidden: false,
              component: 'docManagement/template/index.vue',
              permissions: ['Template Management.query']
            },
            // 包管理
            {
              name: 'docManagement.package',
              meta: {
                module: 'docManagement',
                function: 'Package Management'
              },
              path: '/docManagement/package/index',
              hidden: false,
              component: 'docManagement/package/index.vue',
              permissions: ['Package Management.query']
            }
          ]
        },
        {
          name: 'COI',
          meta: {
            icon: require('../assets/icons/coi.svg'),
            alive: true
          },
          path: '/COI/ToDo',
          hidden: false,
          redirect: '/COI/ToDo/index',
          component: 'RouteView',
          permissions: ['COI Record.query'],
          children: [
            {
              name: 'COI.toDo',
              meta: {
                module: 'docManagement',
                function: 'template management'
              },
              path: '/COI/ToDo/index',
              hidden: false,
              component: 'COI/ToDo/index.vue',
              permissions: ['COI Record.query']
            }
          ]
        },
        {
          name: 'report',
          meta: {
            icon: require('../assets/icons/Report.svg'),
            alive: true
          },
          path: '/report/personnelChange',
          hidden: false,
          redirect: '/report/personnelChange/index',
          component: 'RouteView',
          permissions: ['Personnel Change.query'],
          children: [
            {
              name: 'report.personnelChange',
              meta: {
                module: 'report',
                function: 'template management'
              },
              path: '/report/personnelChange/index',
              hidden: false,
              component: 'report/personnelChange/index.vue',
              permissions: ['Personnel Change.query']
            }
          ]
        },
        {
          name: 'rbac',
          meta: {
            icon: require('../assets/icons/people.svg'),
            alive: true
          },
          path: '/system/rbac',
          hidden: false,
          redirect: '/system/rbac/user-list',
          component: 'RouteView',
          permissions: ['Role.query', 'User.query', 'Permission.query','Email.query'],
          children: [
            {
              name: 'rbac.user',
              meta: {
                module: 'System',
                function: 'User Management'
              },
              path: '/system/rbac/user-list',
              hidden: false,
              component: 'system/rbac/user/UserList.vue',
              permissions: ['User.query']
            },
            {
              name: 'rbac.role',
              meta: {
                module: 'System',
                function: 'Role Management'
              },
              path: '/system/rbac/role-list',
              hidden: false,
              component: 'system/rbac/role/RoleList.vue',
              permissions: ['Role.query']
            },
            {
              name: 'rbac.permission',
              meta: {
                module: 'System',
                function: 'Permission Management'
              },
              path: '/system/rbac/permission-list',
              hidden: false,
              component: 'system/rbac/permission/PermissionList.vue',
              permissions: ['Permission.query']
            },
            {
                name: 'rbac.email',
                meta: { module: 'System', function: 'Email' },
                path: '/system/rbac/email-list',
                hidden: false,
                component: 'system/rbac/email/email.vue',
                permissions: ['Email.query']
            }
            // {
            //   name: 'rbac.department',
            //   meta: {
            //     module: 'System',
            //     function: 'Department Management'
            //   },
            //   path: '/system/rbac/department-list',
            //   hidden: false,
            //   component: 'system/rbac/department/department.vue'
            //   // permissions: ['department.query']
            // }
          ]
        }
        // {
        //   name: 'system.dictionary',
        //   meta: {
        //     icon: {
        //       type: 'menu',
        //       theme: 'outlined'
        //     }
        //   },
        //   path: '/system/dict',
        //   hidden: false,
        //   component: 'system/dict/DictList.vue'
        //   // permissions: ['dict.query']
        // }
      ]
    },
    {
      name: 'notFound',
      meta: {},
      path: '/404',
      hidden: true,
      component: 'NotFound',
      permissions: []
    },
    {
      name: 'coiReport',
      meta: {},
      path: '/COIEvaluationReport',
      hidden: true,
      component: 'COIEvaluationReport',
      permissions: []
    }]
}
// , {
//   name: 'notFound',
//   meta: {},
//   path: '/404',
//   hidden: true,
//   component: 'NotFound',
//   permissions: []
// }
];
export default routes;
