/*
 * @Date: 2021-02-23 09:04:13
 * @Description: Action Button
 */
import './index.less';

export default {
  name: 'b-action-btn',
  render(h, context) {
    const { data, props, children } = context;
    const { title, icon } = props;

    props.type = icon;
    if (!data.attrs) {
      data.attrs = { style: '' };
    } else {
      data.attrs.title = '';
    }

    delete props.title;
    data.class = `${data.class || ''} action-btn`;
    let btn;
    if (icon) {
      btn = h('a-icon', { ...data, props });
    } else {
      btn = children;
    }
    return h('a-tooltip', { props: { title: title || '' } }, [btn]);
  },
  functional: true
};
