import { api } from './tokenApi.js';

// 可以自定义axios基础配置
// import axios from "axios";
// const core = axios.create({ baseURL: 'http://localhost:8080/api/v1/', timeout: 60000 });

/** 发起请求，返回响应对象 */
export function getResponse(axiosOpts) {
  return api.request(axiosOpts, {}).getResponse();
}

/** 发起请求，返回响应内容，自动处理http错误 */
export function getBody(axiosOpts) {
  return api.request(axiosOpts, {}).getBody();
}

/** 发起请求，返回响应内容的data，自动处理业务错误 */
export function getData(axiosOpts) {
  return api.request(axiosOpts, {}).getData();
}

export const apiRequest = api;

export async function download(url, params, fileName) {
  const res = await api.request({
    methods: 'get',
    url,
    params,
    responseType: 'blob'
  }).getResponse();

  const blob = new Blob([res.data], {
    // 创建一个新的Blob对象来接收后端的文件,这里第一个参数必须是数组类型，否则下载必出错。
    type: 'application/octet-stream'
    // type，表明该 Blob 对象所包含数据的 MIME 类型,这需要前后端统一规划
  });
  const link = document.createElement('a');
  const body = document.querySelector('body');
  link.href = window.URL.createObjectURL(blob); // 创建一个下载文件的URL，它指向blob，因为Blob对象在之前在接收后端发来的文件流信息。因为是Blob对象，所以不会跳转页面
  // 以后还有新增的东西的话再加
  link.download = fileName || 'system'; // 自己制定下载文件的文件名
  // 兼容火狐浏览器
  link.style.display = 'none';	// 让这个a标签不可见
  body.appendChild(link);
  link.click();		// 创建了新的a标签之后模拟点击事件，开始传输文件
  body.removeChild(link);	// 下载完成之后，移除按钮，垃圾回收，减少页面内存消耗
  window.URL.revokeObjectURL(link.href);	// 移除之前使用createObjectURL创建的URL，垃圾回收
}

export async function upload(url, file, params) {
  const formData = new FormData();
  formData.append('file', file);
  if (params) {
    for (const i in params) {
      if (params.hasOwnProperty(i)) {
        formData.append(i, params[i]);
      }
    }
  }

  const res = await api.request({
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    url,
    data: formData
  }).getResponse();

  return res.data;
}

export default getData;

export const request = getData;
