import ProLayout from '@/layout/Default.vue';
import RouteView from '@/layout/RouteView';

import NotFound from '@/views/exception/404.vue';
import COIEvaluationReport from '@/views/exception/COIEvaluationReport.vue';

export default {
  NotFound,
  ProLayout,
  RouteView,
  COIEvaluationReport
};
