import Vue from 'vue';

const loading = (store) => {
  store.registerModule('loading', {
    namespaced: true,
    state: {
      actions: {}
    },
    mutations: {
      showLoading: (state, { type }) => {
        Vue.set(state.actions, type, true);
      },
      hideLoading: (state, { type }) => {
        if (Object.prototype.hasOwnProperty.call(state.actions, type)) {
          state.actions[type] = false;
        } else {
          Vue.set(state.actions, type, false);
        }
      }
    }
  });

  store.subscribeAction({
    before: (action) => {
      // NODE_ENV === 'development'
      //           && console.warn(
      //             `%c 🍔 ➤ loading/SHOW_LOADING => ${action.type} `,
      //             'color: #fff; background-color: #92a2bd;'
      //           );
      store.commit('loading/showLoading', { type: action.type });
    },
    after: (action) => {
      // NODE_ENV === 'development'
      //           && console.warn(
      //             `%c 🍟 ➤ loading/HIDE_LOADING => ${action.type} `,
      //             'color: #fff; background-color: #999;'
      //           );
      store.commit('loading/hideLoading', { type: action.type });
    }
  });
};

export default loading;
