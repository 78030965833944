<template>
    <base-layout class="side-menu-layout">
        <template #header>
            <div
                class="side-menu-sidebar"
                :class="{ collapsed: collapsed }"
                :style="{ width: !collapsed ? '228px' : '48px' }"
            >
                <div class="logo">
                    <img :src="logo" width="24" alt="Logo" />
                    <div class="title">{{ title }}</div>
                </div>

                <menus
                    mode="horizontal"
                    class="sidebar-menus"
                    @click="click4menu"
                    :inline-indent="16"
                    :inline-collapsed="collapsed"
                    :selectedKeys="selectedKeys"
                    :openKeys.sync="openKeys"
                    :menus="menus"
                    :overflowed-indicator="null"
                ></menus>
                <div class="siderbar-collapsed">
                    <a-icon
                        type="double-left"
                        @click="toggle"
                        :style="{ transform: `rotateZ(${!collapsed ? 0 : 180}deg)` }"
                    />
                </div>
            </div>
        </template>
        <template #default>
            <div class="side-menu-banner">
                <slot name="header-right"></slot>
            </div>
            <div class="side-menu-body">
                <slot></slot>
            </div>
        </template>
    </base-layout>
</template>

<script>
import layout from '../../mixin/layout.mix';
import BaseLayout from '../../components/BaseLayout/index.vue';

export default {
  name: 'side-menu-layout',
  components: {
    BaseLayout
  },
  mixins: [layout],
  data() {
    return {};
  }
};
</script>
<style lang="less" scoped>
@import 'index.less';
</style>
