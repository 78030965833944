export default {
  name: 'router-view-custom',
  props: {
    alive: {
      type: Boolean,
      default: false
    }
  },
  render(h) {
    const {
      $route: { matched }
    } = this;

    const alive = matched.some(({ meta }) => meta.alive === true);
    if (!this.alive && !alive) {
      return h('router-view');
    }
    return h(
      'keep-alive',
      {},
      [
        h('router-view')
      ]
    );
  }
};
