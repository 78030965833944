const setDocumentTitle = (title, prefixed) => {
  document.title = title;
  if (prefixed) {
    document.title = `${title} - ${prefixed}`;
  }
  const ua = navigator.userAgent;
  // eslint-disable-next-line
    const regex = /\bMicroMessenger\/([\d\.]+)/;
  if (regex.test(ua) && /ip(hone|od|ad)/i.test(ua)) {
    const i = document.createElement('iframe');
    i.src = '/favicon.ico';
    i.style.display = 'none';
    const time = 9;
    i.onload = () => {
      setTimeout(() => {
        i.remove();
      }, time);
    };
    document.body.appendChild(i);
  }
};
export {
  // eslint-disable-next-line import/prefer-default-export
  setDocumentTitle
};
