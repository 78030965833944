import router from '@/router';
import { resolveRoutes } from '@/router/utils';
import { getPrimaryType } from '@/utils';

export default {
  namespaced: true,
  state() {
    return {
      menus: []
    };
  },
  actions: {
    // eslint-disable-next-line consistent-return
    async initMenus({ commit, rootGetters }) {
      try {
        // eslint-disable-next-line global-require
        let menus = require('@/router/routes.config').default;
        if (getPrimaryType(menus) !== 'Array') {
          menus = [menus];
        }
        const routers = resolveRoutes(menus, rootGetters['user/permissions']);
        const initRedirect = (items) => {
          items.forEach((item) => {
            const { children } = item;
            if (children) {
              initRedirect(item.children);
            }
            if (item.redirect) {
              children.forEach((child, index) => {
                if (item.redirect === child.path) {
                  return;
                }
                if (children.length - 1 === index && item.redirect !== child.path) {
                  if (item.path === '/') {
                    return;
                  }
                  // eslint-disable-next-line no-param-reassign
                  item.redirect = children[0].path;
                }
              });
            }
          });
        };
        initRedirect(routers);
        const routerList = routers[0];
        router.addRoute(routerList);
        commit('setMenus', routers);
        return routers;
      } catch (error) {
        // console.log(error);
      }
    }
  },
  mutations: {
    setMenus(state, data) {
      state.menus = data;
    }
  }
};
