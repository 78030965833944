<template>
    <a-select v-model="selfvalue" @select="change" :placeholder="placeholder">
        <a-select-option v-for="item in dictOptions" :key="item.itemKey" :value="item.itemKey">
            {{ item.itemValue }}
        </a-select-option>
    </a-select>
</template>

<script>
import { getDictionaryByKey } from '@/api/system/meta';

export default {
  name: 'b-dict',
  model: {
    prop: 'value',
    event: 'onChange'
  },
  props: {
    dictKey: {
      type: String,
      required: true
    },
    value: {
      type: [String, Number]
    },
    placeholder: {
      type: String,
      default: 'Please Select'
    }
  },
  data() {
    return {
      selfvalue: '',
      dictOptions: []
    };
  },
  methods: {
    getOptions(key) {
      getDictionaryByKey(key).then((data) => {
        this.dictOptions = data;
      });
    },
    init() {
      this.selfvalue = this.value;
      if (this.dictKey) {
        this.getOptions(this.dictKey);
      }
    },
    change(val) {
      this.$emit('onChange', val);
    }
  },
  watch: {
    dictKey(val, oldVal) {
      if (val !== oldVal) {
        this.getOptions(val);
      }
    },
    value(val) {
      if (val !== this.selfvalue) {
        this.selfvalue = val;
      }
    }
  },
  created() {
    this.init();
  }
};
</script>
