<template>
    <div class="page-header-container">
<!--        <select-lang />-->
<!--        <a-divider-->
<!--            style="margin: 0 24px;background-color: rgba(52, 81, 148, 0.3)"-->
<!--            type="vertical"-->
<!--        />-->
        <span class="account-avatar" v-if="$store.state.user.info.username">
            <a-avatar :src="userIcon" />
        <span class="username">{{ $store.state.user.info.username }}</span>

    </span>
        <span class="account-avatar" v-else>
        <a-spin />
    </span>
    </div>
</template>

<script>
import avatar from '@/assets/img/avatar.png';
// import SelectLang from '@/layout/components/LangSelector/index.vue';
// eslint-disable-next-line import/extensions
import { api } from '@/utils/request/tokenApi.js';

export default {
  name: 'right-header',
  // components: {
  //   SelectLang
  // },
  data() {
    return {
      showMenu: true,
      currentUser: this.$store.state.user.info.nickname,
      userIcon: avatar
    };
  },
  methods: {
    handleChangeUser(res) {
      const token = {
        access: String(res.accessToken),
        refresh: String(res.refreshToken),
        expire: Date.now() + Number(res.accessExpire) * 1000
      };
      api.storage(token);
      window.location.reload();
    }
  }
};
</script>
<style lang="less" scoped>
@import (reference) '~@/assets/styles/variables.less';

.page-header-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
    margin-left: auto;
    padding-right: 50px;

    ///deep/ .ant-divider,
    ///deep/ .ant-divider-vertical {
    //    top: 0;
    //}
}
.account-avatar {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .username {
        margin-left: 8px;
        color: @logoColor;
        font-size: 12px;
        line-height: 20px;
    }
    /deep/ .ant-avatar {
        color: @g20;
        line-height: 30px;
        background-color: transparent;
        border: solid 1px @g20;
    }
    /deep/ .ant-spin {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
</style>
